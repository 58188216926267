// #region Imports

import {
    ContractCrudService,
    ContractFunctionService,
    IContractDocumentDto,
    IContractManagementOverviewDto,
    IIsReadyResultDto,
    IPurchaseEntryDto,
    IPurchaseEntryStatusDto,
    ISaleAndLeaseBackCodeDto,
    PurchaseEntryFunctionService,
} from '@abcfinlab/api/contract';
import {
    ContractsDeprecatedService,
    IContractManagementDetailsDto,
    IContractTypeDto,
    QuoteService,
} from '@abcfinlab/api/global';
import { EventHub, Globals, TranslationFacade } from '@abcfinlab/core';
import { convertNavStatusToNumber, UiStatesPreservationService } from '@abcfinlab/presentation';
import { BusyBoxService, MessageBoxButton, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen, take } from 'rxjs/operators';
import { UploadDocumentType } from '../../../../apps/shell/src/app/models/enums/UploadDocumentType.enum';
import { IAccountingDialogData } from '../Models/IAccountingDialogData';
import { IContractManagementOverviewInfo } from '../Models/IContractManagementOverviewInfo';
import { IContractManagementOverviewSearchCondition } from '../Models/IContractManagementOverviewSearchCondition';
import { KnownEvents } from '../Models/KnownEvents';
import { KnownSettings } from '../Models/KnownSettings';
import { AccountingDialogComponent } from './Dialogs/accounting-dialog/accounting-dialog.component';

// #endregion

export interface ITableColumnDef {
    visible: boolean | Observable<boolean>;
}

/**
 * @private
 */
const EMPTY_SEARCH_CONDITION: IContractManagementOverviewSearchCondition = {
    term: '',
};

/**
 * @private
 */
const isEmptySearchCondition = (other: IContractManagementOverviewSearchCondition) => EMPTY_SEARCH_CONDITION.term.toLowerCase() === other.term.toLowerCase();

/**
 * The `ContractOverviewViewComponent` component.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-contract-overview-view',
    templateUrl: './ContractOverviewView.html',
    styleUrls: ['./ContractOverviewView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('detailExpand', [
            state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('*', style({ height: '*', visibility: 'visible' })),
            transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ContractOverviewView implements OnInit {

    // #region Fields

    @ViewChild(MatSort, { static: true })
    private readonly _sort!: MatSort;

    private readonly _eventHub: EventHub;
    private readonly _uiStatesPreservationService: UiStatesPreservationService;
    private readonly _contractFunctionService: ContractFunctionService;
    private readonly _contractService: ContractCrudService;
    private readonly _quoteService: QuoteService;
    private readonly _contractsDeprecatedService: ContractsDeprecatedService;
    private readonly _cdr: ChangeDetectorRef;
    private readonly _columns: Record<string, ITableColumnDef>;
    private readonly _renderer: Renderer2;
    private readonly _dialog: MatDialog;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _toastService: ToastService;
    private readonly _purchaseEntryService: PurchaseEntryFunctionService;
    private readonly _datePipe: DatePipe;
    private readonly _conditionSubject: BehaviorSubject<IContractManagementOverviewSearchCondition>;
    private readonly _isDefaultSearchConditionSubject: BehaviorSubject<boolean>;

    private readonly _translatableErrorMessages: Array<string> = [
        'purchase_entry_not_allowed',
        'purchase_entry_versions_or_quote_not_match',
        'purchase_entry_wrong_status',
        'purchase_entry_slb',
        'purchase_entry_creation_null',
    ];

    private readonly _pageSizeSubject: BehaviorSubject<number>;
    private readonly _pageSizesSubject: BehaviorSubject<Array<number>>;
    private readonly _dataSource: MatTableDataSource<IContractManagementOverviewInfo>;
    private readonly _dataSourceTotalSubject: BehaviorSubject<number>;
    private _overviewIsReadyResult: IIsReadyResultDto | null;
    private _contractNavStatus: number;
    private _overview: IContractManagementOverviewDto | null;
    private readonly _hasRefinancingInterestIncrease: BehaviorSubject<boolean>;
    private readonly _isRecalculationPanelOpen: BehaviorSubject<boolean>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `ContractOverviewView` class.
     *
     * @public
     */
    public constructor(contractFunctionService: ContractFunctionService, contractsDeprecatedService: ContractsDeprecatedService, cdr: ChangeDetectorRef, dialog: MatDialog,
        translationFacade: TranslationFacade, renderer: Renderer2, toastService: ToastService, busyBoxService: BusyBoxService, purchaseEntryService: PurchaseEntryFunctionService,
        eventHub: EventHub, contractService: ContractCrudService, datePipe: DatePipe, uiStatesPreservation: UiStatesPreservationService,
        quoteService: QuoteService, messageBoxService: MessageBoxService) {
        this._contractFunctionService = contractFunctionService;
        this._contractService = contractService;
        this._quoteService = quoteService;
        this._uiStatesPreservationService = uiStatesPreservation;
        this._cdr = cdr;
        this._renderer = renderer;
        this._dialog = dialog;
        this._messageBoxService = messageBoxService;
        this._translationFacade = translationFacade;
        this._eventHub = eventHub;
        this._busyBoxService = busyBoxService;
        this._toastService = toastService;
        this._purchaseEntryService = purchaseEntryService;
        this._contractsDeprecatedService = contractsDeprecatedService;
        this._datePipe = datePipe;

        this._columns = {
            lessee: { visible: true },
            contract_number: { visible: true },
            object_name: { visible: true },
            signing_date: { visible: true },
            contract_type: { visible: true },
            state: { visible: true },
            send: { visible: true },
            processing_status: { visible: true },
        };

        this._dataSource = new MatTableDataSource();
        this._conditionSubject = new BehaviorSubject(EMPTY_SEARCH_CONDITION);
        this._isDefaultSearchConditionSubject = new BehaviorSubject(true);
        this._pageSizeSubject = new BehaviorSubject(Globals.Page.DEFAULT_PAGE_SIZE);
        this._pageSizesSubject = new BehaviorSubject(Globals.Page.DEFAULT_PAGE_SIZES);
        this._dataSourceTotalSubject = new BehaviorSubject(0);
        this._hasRefinancingInterestIncrease = new BehaviorSubject<boolean>(false);
        this._isRecalculationPanelOpen = new BehaviorSubject<boolean>(false);
        this._overviewIsReadyResult = null;
        this._overview = null;
    }

    // #endregion

    // #region Properties

    public get documentTypes(): typeof UploadDocumentType {
        return UploadDocumentType;
    }

    public selectedPurchaseEntry$: BehaviorSubject<IPurchaseEntryDto> = new BehaviorSubject<IPurchaseEntryDto>(null);
    public selectedQuoteDetails$: BehaviorSubject<IContractManagementDetailsDto> = new BehaviorSubject<IContractManagementDetailsDto>(null);
    public loadingPurchaseEntry$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public uploadedDocuments$: BehaviorSubject<Array<IContractDocumentDto>> = new BehaviorSubject<Array<IContractDocumentDto>>([]);
    public newDocuments$: BehaviorSubject<Array<IContractDocumentDto>> = new BehaviorSubject<Array<IContractDocumentDto>>([]);
    public canUploadFiles$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected readonly IContractTypeDto = IContractTypeDto;

    /**
     * Returns the `columns` property.
     *
     * @public
     * @readonly
     */
    public get columns(): Record<string, ITableColumnDef> {
        return this._columns;
    }

    /**
     * Returns the `dataSource` property.
     *
     * @public
     * @readonly
     */
    public get dataSource(): MatTableDataSource<IContractManagementOverviewInfo> {
        return this._dataSource;
    }

    /**
     * Returns the `dataSourceTotal` property.
     *
     * @public
     * @readonly
     */
    public get dataSourceTotal(): Observable<number> {
        return this._dataSourceTotalSubject.asObservable();
    }

    /**
     * Returns the `pageSize` property.
     *
     * @public
     * @readonly
     */
    public get pageSize(): Observable<number> {
        return this._pageSizeSubject.asObservable();
    }

    /**
     * Returns the `pageSizes` property.
     *
     * @public
     * @readonly
     */
    public get pageSizes(): Observable<Array<number>> {
        return this._pageSizesSubject.asObservable();
    }

    /**
     * Returns the `overview` property.
     *
     * @public
     * @readonly
     */
    public get overviewIsReadyResult(): IIsReadyResultDto | null {
        return this._overviewIsReadyResult;
    }

    /**
     * Returns the `overview` property.
     *
     * @public
     * @readonly
     */
    public get overview(): IContractManagementOverviewDto | null {
        return this._overview;
    }

    /**
     * Returns the `isDefaultSearchCondition` property.
     *
     * @public
     * @readonly
     */
    public get isDefaultSearchCondition(): Observable<boolean> {
        return this._isDefaultSearchConditionSubject.asObservable();
    }

    /**
     * Returns the `condition` property.
     *
     * @public
     * @readonly
     */
    public get condition(): Observable<IContractManagementOverviewSearchCondition> {
        return this._conditionSubject.asObservable();
    }

    /**
     * Returns the `canRecalculate` property.
     *
     * @public
     * @readonly
     */
    public get canRecalculate(): Observable<boolean> {
        return this._hasRefinancingInterestIncrease.asObservable();
    }

    public get isRecalculationPanelOpen(): Observable<boolean> {
        return this._isRecalculationPanelOpen.asObservable();
    }

    public get contractStatusLowerThen50(): boolean {
        return this._contractNavStatus < 50;
    }

    public get contractStatusGreaterThen50(): boolean {
        return this._contractNavStatus >= 50;
    }

    // #endregion

    // #region Methods

    public ngOnInit(): void {
        this.initializeCondition();
    }

    public isContractReady(value: Array<string>): void {
        this._contractFunctionService.isReadyForAccounting({ body: value })
            .pipe(untilDestroyed(this))
            .subscribe((x) => {
                x.forEach((y) => {
                    this._dataSource.data.filter(q => q.contract_number === y.contract_number).forEach((z) => {
                        z.ready = y;
                    });
                });
                this._cdr.detectChanges();
            });
    }

    public setUploadedDocuments(files: Array<IContractDocumentDto>): void {
        files.sort((a, b) => {
            if (a.creationDate < b.creationDate) {
                return -1;
            }
            return 1;
        });
        this.uploadedDocuments$.next([...files]);
    }

    public setNewUploadedDocuments(files: Array<IContractDocumentDto>): void {
        this.newDocuments$.next(files);
        this.uploadedDocuments$.next(this.uploadedDocuments$.getValue().concat(files));
    }

    /**
     * @internal
     */
    public onTermChanged(value: string): void {
        const condition = {
            ...this._conditionSubject.value,
            term: value.trim().toLowerCase(),
        };

        this._conditionSubject.next(condition);
        this._isDefaultSearchConditionSubject.next(isEmptySearchCondition(this._conditionSubject.value));
        this._uiStatesPreservationService.set(KnownSettings.ContractManagementFilterCondition, condition);

        this.initializeContracts(this._pageSizeSubject.value, 0);
    }

    /**
     * @internal
     */
    public onRowSelected(row: IContractManagementOverviewDto) {
        this._overview = row;
        this._eventHub.publish<IContractManagementOverviewDto>(KnownEvents.CONTRACT_OVERVIEW_ROW_CHANGED, row);
        this.getContractStatus(row.contract_number);
        this._contractFunctionService.isReadyForAccounting({ body: [row.contract_number] })
            .pipe(untilDestroyed(this))
            .subscribe((x) => {
                this._overviewIsReadyResult = x[0];
            });

        combineLatest([
            this._quoteService.getQuoteCalculation({ leasingQuoteId: row.quote_id }).pipe(take(1), catchError(() => of(null))),
            this._quoteService.getRefinancingInterest({ quoteId: row.quote_id }).pipe(take(1), catchError(() => of(null))),
        ]).pipe(untilDestroyed(this))
            .subscribe(([calculation, refinancingInterest]) => {
                this._hasRefinancingInterestIncrease.next(refinancingInterest.interest > calculation.refinancing_rate);
            });
    }

    /**
     * @internal
     */
    public onRowCollapsed() {
        this._overviewIsReadyResult = null;
        this._isRecalculationPanelOpen.next(false);
        this._hasRefinancingInterestIncrease.next(false);
        this._cdr.detectChanges();
    }

    /**
     * @internal
     */
    public onExpansionExpanded(tab?: string) {
        switch (tab) {
            case 'PURCHASE_ENTRY':
                this.onGetPurchaseEntry();
                break;
            case 'RECALCULATION':
                this._isRecalculationPanelOpen.next(true);
                break;
        }
        this._cdr.detectChanges();
    }

    /**
     * @internal
     */
    public onCheckState(event: MouseEvent, contractNumber: string): void {
        event.stopPropagation();
        const elementRef = event.currentTarget as HTMLElement;

        this._renderer.setStyle(elementRef.querySelector('p'), 'display', 'none');
        this._renderer.setStyle(elementRef.getElementsByClassName('mat-spinner')[0], 'display', 'block');
        this.getContractStatus(contractNumber);
    }

    /**
     * @internal
     */
    public onCreatePurchaseEntry(addresses: Array<string>) {
        this.loadingPurchaseEntry$.next(true);
        this._purchaseEntryService.createPurchaseEntry({
            quoteId: this._overview.quote_id, body: { addresses } as any,
        }).pipe(
            untilDestroyed(this),
            retryWhen(err => err.pipe(
                delay(2500),
                concatMap((err, count: number) => {
                    if (err.status === 409 && count < 1) {
                        console.warn('E-Mail-Address potentially bounced.');
                        return of(err);
                    }
                    return throwError(err);
                }),
            ),
            ),
        ).subscribe(
            res => this.handleCreatePurchaseEntrySuccess(res, addresses),
            err => this.handleCreatePurchaseEntryError(err),
        );
    }

    /**
     * Handle the success of creating a *PurchaseEntry*
     * @param purchaseEntryData
     * @param addresses A list of email addresses
     */
    handleCreatePurchaseEntrySuccess(purchaseEntryData: IPurchaseEntryDto, addresses: Array<string>) {
        this.selectedPurchaseEntry$.next({
            purchase_entry_id: purchaseEntryData.purchase_entry_id,
            addresses,
            creation_date: new Date().toDateString(),
            status: IPurchaseEntryStatusDto.Created,
            object_value: purchaseEntryData.object_value,
        });
        this.loadingPurchaseEntry$.next(false);
        this._cdr.detectChanges();
    }

    /**
     * Handle the error(s) that possibly occour(s) on the creation of a *PurchaseEntry*
     * @param err
     */
    handleCreatePurchaseEntryError(err: any): void {
        this.loadingPurchaseEntry$.next(false);
        this.displayErrorDialog(err);
    }

    /**
     * @internal
     */
    public onChangeObjectValueForPurchaseEntry(objValue: number) {
        this.loadingPurchaseEntry$.next(true);
        this._purchaseEntryService.changeObjectPrice({
            quoteId: this._overview.quote_id,
            body: {
                object_value: objValue,
            } as any,
        }).pipe(
            untilDestroyed(this),
        ).subscribe(() => {
            this.selectedPurchaseEntry$.next({ ...this.selectedPurchaseEntry$.getValue(), ...{ object_value: objValue } });
            this.loadingPurchaseEntry$.next(false);
            this._cdr.detectChanges();
        }, (error) => {
            this.loadingPurchaseEntry$.next(false);
            this._toastService.show(error.message, 'danger');
            this._cdr.detectChanges();
        });
    }

    /**
     * @internal
     */
    public onSendContract(event: MouseEvent, quoteId: string, contractNumber: string, slb: boolean, slbCode: ISaleAndLeaseBackCodeDto): void {
        event.stopPropagation();
        this._dialog.open<AccountingDialogComponent, IAccountingDialogData>(AccountingDialogComponent, {
            minWidth: '390px',
            data: {
                quoteId: quoteId,
                contractNumber: contractNumber,
                slb: slb,
                slbCode: slbCode,
            },
        }).afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((x) => {
                if (!x.canceled) {
                    this._busyBoxService.show('', this._translationFacade.translate('global.busy'),
                        this._contractFunctionService.submitToAccounting({ body: x.data }).pipe(untilDestroyed(this))).subscribe(() => {
                        this.isContractReady([contractNumber]);
                        this._toastService.show(this._translationFacade.instant('contract_management.document_sent'), 'success');
                    },
                    (_error) => {
                        if (_error.status === 409) {
                            this.displayErrorDialog(_error);
                        } else {
                            this._toastService.show(_error.message, 'danger');
                        }
                    });
                }
            });
    }

    /**
     * @internal
     */
    public onPageChanged(page: PageEvent): void {
        this._pageSizeSubject.next(page.pageSize);
        this.initializeContracts(page.pageSize, page.pageIndex);
    }

    /**
     * @internal
     */
    public onResetConditions(): void {
        this._conditionSubject.next(EMPTY_SEARCH_CONDITION);
        this._isDefaultSearchConditionSubject.next(true);
        this._uiStatesPreservationService.remove(KnownSettings.ContractManagementFilterCondition);

        this.initializeContracts(this._pageSizeSubject.value, 0);
    }

    /**
     * @private
     */
    private getContractStatus(contractNumber: string) {
        this._contractFunctionService.getContractStatus({ contractNumbers: [contractNumber] })
            .pipe(untilDestroyed(this))
            .subscribe((status) => {
                this._dataSource.filteredData.filter(contract => contract.contract_number === contractNumber).forEach((contract) => {
                    this._contractNavStatus = convertNavStatusToNumber(status[0].status);

                    this.canUploadFiles$.next(this.contractStatusLowerThen50);
                    contract.status = status[0].status;
                    this._cdr.detectChanges();
                    return contract;
                });
            });
    }

    /**
     * @private
     */
    private onGetPurchaseEntry() {
        this.loadingPurchaseEntry$.next(true);
        combineLatest([
            this._purchaseEntryService.getPurchaseEntryForQuoteId({ quoteId: this._overview.quote_id }),
            this._contractsDeprecatedService.getContractDetails({ contractNumber: this._overview.contract_number }),
        ]).pipe(
            untilDestroyed(this),
        ).subscribe(
            ([purchaseEntry, quoteDetails]) => {
                this.loadingPurchaseEntry$.next(false);
                this.selectedPurchaseEntry$.next(purchaseEntry);
                this.selectedQuoteDetails$.next(quoteDetails);
                this._cdr.detectChanges();
            },
            (error) => {
                this.loadingPurchaseEntry$.next(false);
                this.displayErrorDialog(error);
                this._cdr.detectChanges();
            },
        );

        this._cdr.detectChanges();
    }

    /**
     * @private
     */
    private displayErrorDialog(_error: HttpErrorResponse): void {
        let dialogMessage; // will be redefined depending on the error case
        const now = this._datePipe.transform(new Date(), 'dd.MM.yyyy HH:mm', 'UTC+1');
        const dialogLabels = { labels: { ok: this._translationFacade.instant('global.ok') } };

        if (_error.status !== 500) { // do not handle error with status 500, it is handle by interception
            dialogMessage = this._translationFacade.instant('error.signature_document_saving', { param1: _error.error.error_description, date: now });

            if (_error.status === 409 && !this._translatableErrorMessages.includes(_error.error.error)) {
                dialogMessage = this._translationFacade.instant('error.purchase_entry_email_bounced');
            }

            if (_error.status === 409 && this._translatableErrorMessages.includes(_error.error.error)) {
                dialogMessage = this._translationFacade.instant(`error.${_error.error.error}`);
            }

            this._messageBoxService.show('', dialogMessage, MessageBoxButton.OK, dialogLabels);
        }
    }

    /**
     * @private
     */
    private initializeContracts(size: number, index: number): void {
        this._busyBoxService.show('', this._translationFacade.translate('global.busy'), this._contractService.getContractsForLead({
            page: index,
            pageSize: size,
            searchString: this._conditionSubject.value.term,
        }).pipe(untilDestroyed(this)))
            .subscribe((data) => {
                const contractNumbers = [];
                data.content.forEach((x) => {
                    contractNumbers.push(x.contract_number);
                });
                this._dataSource.data = data.content;
                this._dataSource.sort = this._sort;
                this.dataSource.sortingDataAccessor = (data, sortHeaderId): string | number => {
                    const propPath = sortHeaderId.split('.');
                    const value: any = propPath.reduce(
                        (curObj, property) => curObj[property],
                        data,
                    );
                    return !isNaN(value) ? Number(value) : value;
                };

                this._dataSourceTotalSubject.next(data.totalElements);

                if (contractNumbers) {
                    this.isContractReady(contractNumbers);
                }
                this._cdr.detectChanges();
            });
    }

    /**
     * @private
     */
    private initializeCondition(): void {
        this._uiStatesPreservationService.get<IContractManagementOverviewSearchCondition>(KnownSettings.ContractManagementFilterCondition).subscribe((x) => {
            if (x) {
                this._conditionSubject.next(x);
                this._isDefaultSearchConditionSubject.next(false);
            }
            this.initializeContracts(this._pageSizeSubject.value, 0);
        });
    }

    // #endregion

}
