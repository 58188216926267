// #region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import { SpacerModule } from '../Spacer/SpacerModule';
import { TextBlockModule } from '../TextBlock/TextBlockModule';
import { PersonaCardComponent } from './PersonaCardComponent';
import { PersonaComponent } from './PersonaComponent';
import { PersonaGroupComponent } from './PersonaGroupComponent';

// #endregion

/**
 * @public
 */
@NgModule({
    declarations: [
        PersonaComponent,
        PersonaGroupComponent,
        PersonaCardComponent,
    ],
    exports: [
        PersonaComponent,
        PersonaGroupComponent,
        PersonaCardComponent,
    ],
    imports: [
        CommonModule,
        TextBlockModule,
        MatIconModule,
        FlexLayoutModule,
        SpacerModule,
    ],
})
export class PersonaModule {
}
