import {
    AccountingService, AcknowledgementService, ContractCalculationCrudService, ContractCrudService,
    IAccountingDto,
    IAcknowledgementDto, IAcknowledgementWithoutSigningDto,
    IContractWithStartOfContractDto, IInstalmentCalculationDto,
    IInsuranceAndHandlingDto, InstalmentCalculationService, ISlbGetResponseDto,
    ISubmitContractDto, SlbService,
} from '@abcfinlab/api/contract';
import {
    ContractsDeprecatedService, CreditCheckService,
    ICalculationDto,
    IContractManagementDetailsDto, ICreditCheckDto, IInhouseQuoteDto,
    IInsuranceTypeDto,
    IRefinancingInterestDto, ISaleAndLeaseBackCodeDto,
    IUserInfoDto,
    KeycloakUsersService,
    QuoteService,
    VerificationService,
} from '@abcfinlab/api/global';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { calculateInsuranceValueRange } from '../../../../../../apps/shell/src/app/helper/calculation.helper';
import { ErrorHandlerService } from '../../../../../../apps/shell/src/app/shared/services/errorHandler/error-handler.service';
import { TranslationFacade } from '../../../../../core/src/Services/TranslationFacade';
import { IAccountingDialogData } from '../../../Models/IAccountingDialogData';

interface IAccountingInfo {
    accounting: IAccountingDto;
    acknowledgement: IAcknowledgementWithoutSigningDto | IAcknowledgementDto;
    details: IContractManagementDetailsDto;
    salesAssistants: Array<IUserInfoDto>;
    quote: IInhouseQuoteDto;
    calculation: ICalculationDto;
    contract: IContractWithStartOfContractDto;
    insuranceAndHandlingFee: IInsuranceAndHandlingDto;
    refinancingInterest: IRefinancingInterestDto;
    creditCheck: ICreditCheckDto;
    changedCalculation: IInstalmentCalculationDto;
    slb: ISlbGetResponseDto;
}

interface ICalculationInfo {
    totalLeasingValueNet: number;
    lastInstalment: number;
    firstInstalment: number;
    instalment: number;
    downPayment: number;
    residualValue: number;
}

@Component({
    selector: 'l7-accounting-dialog',
    templateUrl: './accounting-dialog.component.html',
    styleUrls: ['./accounting-dialog.component.scss'],
})
@UntilDestroy()
export class AccountingDialogComponent implements OnInit {

    // #region Fields

    private readonly _accountingService: AccountingService;
    private readonly _acknowledgementService: AcknowledgementService;
    private readonly _userService: KeycloakUsersService;
    private readonly _quoteService: QuoteService;
    private readonly _creditCheckService: CreditCheckService;
    private readonly _dialogRef: MatDialogRef<any>;
    private readonly _verificationService: VerificationService;
    private readonly _errorHandlerService: ErrorHandlerService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _contractCrudService: ContractCrudService;
    private readonly _contractCalculationService: ContractCalculationCrudService;
    private readonly _contractsDeprecatedService: ContractsDeprecatedService;
    private readonly _instalmentCalculationService: InstalmentCalculationService;
    private readonly _slbService: SlbService;
    private readonly _dataSubject: BehaviorSubject<IAccountingInfo | null>;
    private readonly _slbCode: BehaviorSubject<ISaleAndLeaseBackCodeDto> = new BehaviorSubject<ISaleAndLeaseBackCodeDto>(null);
    private readonly _showKuevData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _changedPurchasePriceValues: BehaviorSubject<ICalculationInfo> = new BehaviorSubject<ICalculationInfo>(null);
    private readonly _hasTransparencyRegister: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private readonly _data: IAccountingDialogData;
    public emails: Array<string>;

    // #endregion

    public constructor(@Inject(MAT_DIALOG_DATA) data: IAccountingDialogData, dialogRef: MatDialogRef<any>, formBuilder: UntypedFormBuilder,
        verificationService: VerificationService, errorHandlerService: ErrorHandlerService, translationFacade: TranslationFacade,
        contractCrudService: ContractCrudService, accountingService: AccountingService, slbService: SlbService,
        contractCalculationService: ContractCalculationCrudService, creditCheck: CreditCheckService,
        acknowledgementService: AcknowledgementService, instalmentCalculationService: InstalmentCalculationService,
        userService: KeycloakUsersService, quoteService: QuoteService, contractsDeprecatedService: ContractsDeprecatedService) {
        this._data = data;
        this._slbCode.next(data.slbCode);
        this._dialogRef = dialogRef;
        this._verificationService = verificationService;
        this._errorHandlerService = errorHandlerService;
        this._translationFacade = translationFacade;
        this._contractCrudService = contractCrudService;
        this._contractCalculationService = contractCalculationService;
        this._contractsDeprecatedService = contractsDeprecatedService;
        this._accountingService = accountingService;
        this._acknowledgementService = acknowledgementService;
        this._userService = userService;
        this._quoteService = quoteService;
        this._creditCheckService = creditCheck;
        this._instalmentCalculationService = instalmentCalculationService;
        this._slbService = slbService;

        this._dataSubject = new BehaviorSubject(null);
        this.accountingForm = formBuilder.group({
            annotation_in_mail: [null, []],
            email: [null, [Validators.required, Validators.email]],
        });
        this.emails = [];
        this.emailsAssistant = [];
    }

    /**
     * Returns the `data` property.
     *
     * @public
     * @readonly
     */
    public get data(): Observable<IAccountingInfo | null> {
        return this._dataSubject.asObservable();
    }

    /**
     * Returns the `hasTransparencyRegister` property.
     *
     * @public
     * @readonly
     */
    public get hasTransparencyRegister(): Observable<boolean> {
        return this._hasTransparencyRegister.asObservable();
    }

    /**
     * Returns the `showKuevData` property.
     *
     * @public
     * @readonly
     */
    public get showKuevData(): Observable<boolean> {
        return this._showKuevData.asObservable();
    }

    /**
     * Returns the `changedPurchasePriceValues` property.
     *
     * @public
     * @readonly
     */
    public get changedPurchasePriceValues(): Observable<ICalculationInfo> {
        return this._changedPurchasePriceValues.asObservable();
    }

    public emailsAssistant: Array<string>;

    public insuranceValueChangedText: string = 'Original';

    public accountingForm: UntypedFormGroup;

    public ngOnInit(): void {
        const getChangedCalculation$ = this._instalmentCalculationService.getInstalmentCalculation({ contractId: this._data.contractNumber }).pipe(take(1), catchError(() => of(null)));
        const getSlb$ = this._data.slb ? this._slbService.getSlb({ contractId: this._data.contractNumber }).pipe(take(1), catchError(() => of(null))) : of(null);
        const getAcknowledgement$ = this._data.slb ? of(null) : this._acknowledgementService.readByContractNumber({ contractNumber: this._data.contractNumber }).pipe(take(1), catchError(() => of(null)));
        const initializer = combineLatest([
            this._accountingService.read({ leasingQuoteId: this._data.quoteId }).pipe(take(1), catchError(() => of(null))),
            getAcknowledgement$,
            this._contractsDeprecatedService.getContractDetails({ contractNumber: this._data.contractNumber }).pipe(take(1), catchError(() => of(null))),
            this._userService.getSalesAssistance({ quoteId: this._data.quoteId }).pipe(take(1), catchError(() => of(null))),
            this._quoteService.getQuoteByIdV({ quoteId: this._data.quoteId }).pipe(take(1), catchError(() => of(null))),
            this._quoteService.getQuoteCalculation({ leasingQuoteId: this._data.quoteId }).pipe(take(1), catchError(() => of(null))),
            this._contractCrudService.read({ contractNumber: this._data.contractNumber }).pipe(take(1), catchError(() => of(null))),
            this._contractCalculationService.getExpenses({ contractId: this._data.contractNumber }).pipe(take(1), catchError(() => of(null))),
            this._quoteService.getRefinancingInterest({ quoteId: this._data.quoteId }).pipe(take(1), catchError(() => of(null))),
            this._creditCheckService.getCreditCheck({ quoteId: this._data.quoteId }).pipe(take(1), catchError(() => of(null))),
            getChangedCalculation$,
            getSlb$,
            this._quoteService.getQuoteById({ quoteId: this._data.quoteId }).pipe(take(1), catchError(() => of(null))),
        ]).pipe(untilDestroyed(this))
            .subscribe(([accounting, acknowledgement, details, salesAssistants, quote, calculation, contract, insuranceAndHandlingFee, refinancingInterest, creditCheck, changedCalculation, slb, oldGetQuote]) => {
                if (salesAssistants) {
                    salesAssistants.forEach((assistant) => {
                        this.emails.push(assistant.email);
                        this.emailsAssistant.push(assistant.email);
                    });
                }

                if (quote) {
                    const insuranceRange = calculateInsuranceValueRange(calculation.total_leasing_value, calculation.insurance_value);
                    const verifyHasInsuranceChanged = calculation.insurance_type === IInsuranceTypeDto.Standard && insuranceAndHandlingFee.insurance_value !== insuranceRange.maxValue ? true : false;

                    if (verifyHasInsuranceChanged) {
                        this.insuranceValueChangedText = 'Geändert';
                    }
                }

                const data = {
                    accounting,
                    acknowledgement,
                    details,
                    salesAssistants,
                    quote: quote,
                    contract,
                    calculation,
                    insuranceAndHandlingFee,
                    refinancingInterest,
                    creditCheck,
                    changedCalculation,
                    slb,
                };
                this._hasTransparencyRegister.next(oldGetQuote.transparency_register_entry);
                this._dataSubject.next(data);
                if (slb && slb.activeSlbResponse && slb.inactiveSlbResponse) {
                    this._showKuevData.next(true);
                }
                if (slb && slb.activeSlbResponse.calculation.totalLeasingValueNet !== data.details.object.value) {
                    this._changedPurchasePriceValues.next({
                        totalLeasingValueNet: slb.activeSlbResponse.calculation.totalLeasingValueNet,
                        lastInstalment: slb.activeSlbResponse.calculation.lastInstalment,
                        firstInstalment: slb.activeSlbResponse.calculation.firstInstalment,
                        instalment: slb.activeSlbResponse.calculation.instalment,
                        downPayment: slb.activeSlbResponse.calculation.downPayment,
                        residualValue: slb.activeSlbResponse.calculation.residualValue,
                    });
                } else if (!slb && data.details?.object?.value !== data.acknowledgement?.purchase_price) {
                    this._changedPurchasePriceValues.next({
                        totalLeasingValueNet: data.acknowledgement?.purchase_price,
                        lastInstalment: data.acknowledgement?.last_instalment,
                        firstInstalment: data.acknowledgement?.first_instalment,
                        instalment: data.acknowledgement?.instalment,
                        downPayment: data.acknowledgement?.down_payment,
                        residualValue: data.acknowledgement?.residual_value,
                    });
                }
            }, () => {
            }, () => {
                initializer.unsubscribe();
            });
    }

    public hideKuevData(): void {
        this._showKuevData.next(false);
    }

    public onClose(canceled: boolean): void {
        if (this.accountingForm.valid && this.accountingForm.get('email').value !== '') {
            this._verificationService.verifyEmail({ emailAddress: this.accountingForm.get('email').value })
                .pipe(untilDestroyed(this))
                .subscribe((_) => {
                    if (!this.emails.includes(this.accountingForm.get('email').value)) {
                        this.emails.push(this.accountingForm.get('email').value);
                    }
                    this.submitData(canceled);
                },
                (_error) => {
                    const dialogMessage = this._translationFacade.instant(`error.${_error.error.error}`,
                        _error.error.error_params);
                    this._errorHandlerService.openDialogWithIdAndBody('error.email_not_valid', _error, dialogMessage);
                    this._errorHandlerService.errorDialogEmailVerification$
                        .pipe(untilDestroyed(this))
                        .subscribe((value) => {
                            if (value) {
                                if (!this.emails.includes(this.accountingForm.get('email').value)) {
                                    this.emails.push(this.accountingForm.get('email').value);
                                }
                                this.submitData(canceled);
                            }
                        });
                });
        } else {
            this.submitData(canceled);
        }
    }

    public addEmails(emails: Array<string>): void {
        emails.forEach((email) => {
            if (!this.emails.includes(email)) {
                this.emails.push(email);
            }
        });
    }

    public removeEmail(email: string): void {
        const index = this.emails.indexOf(email);

        if (index >= 0) {
            this.emails.splice(index, 1);
            this.emailsAssistant.splice(this.emailsAssistant.indexOf(email), 1);
        }
    }

    private submitData(canceled: boolean) {
        const data: ISubmitContractDto = {
            accountant_mails: this.emails,
            annotation_in_mail: this.accountingForm.getRawValue().annotation_in_mail ? this.accountingForm.getRawValue().annotation_in_mail : '',
            contract_number: this._data.contractNumber,
            annotation_bo_in_mail: '',
        };

        this._dialogRef.close({
            data,
            canceled,
        });
    }

}
