import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CameraService } from '../camera.service';

@UntilDestroy()
@Component({
    selector: 'l7-camera-indicator',
    templateUrl: './camera-indicator.component.html',
    styleUrls: ['./camera-indicator.component.scss'],
})
export class CameraIndicatorComponent implements OnInit {

    constructor(
        public cameraService: CameraService,
    ) {
    }

    get camera_is_available(): boolean {
        return this.cameraService.camera_is_available;
    }

    get initial_check_performed(): boolean {
        return this.cameraService.initial_check_done;
    }

    get classes(): any {
        if (this.camera_is_available) {
            return {
                enabled: true,
            };
        } else {
            return {
                disabled: true,
            };
        }
    }

    ngOnInit() {
        this.cameraService.initCamera()
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    /**
   * Recheck availability of the camera device.
   * If the user denied permission on an https connection the browser will save the settings.
   * The user will have to change those settings by hand then.
   */
    public requestCameraPermission(): void {
        this.cameraService.initCamera()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
            }, (err: Error) => {
                if (err.message === 'Permission denied') {
                    console.error('Please update the browser permissions for your camera!');
                }
            });
    }

}
