// #region Imports

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ColorSet } from '../../Color';
import { HostBindingBuilder } from '../../HostBindingBuilder';

// #endregion

/**
 * The `PersonaComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-persona',
    templateUrl: './PersonaComponent.html',
    styleUrls: ['./PersonaComponent.scss'],
    exportAs: 'l7Persona',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonaComponent {

    // #region Fields

    private _text: string;
    private _textAccessor: (value: string) => string;
    private _color: ColorSet;

    // #endregion

    // #region Ctor

    public constructor() {
        this._text = '';
        this._textAccessor = (text: string) => text.split(' ').map(x => x[0]).join('').substring(0, 2);
        this._color = 'neutral';
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the color set type value. This will applied as dom class property.
     *
     * @public
     * @type ColorSet | string
     * @default ColorSet.Default
     */
    @Input()
    public get color(): ColorSet | string {
        return this._color;
    }

    public set color(value: ColorSet | string) {
        this._color = value as ColorSet;
    }

    /**
     * Gets or sets the `text` property.
     *
     * @public
     */
    @Input()
    public get text(): string {
        return this._text;
    }

    public set text(value: string) {
        this._text = value;
    }

    /**
     * Gets or sets the accessor for manipulating the text.
     *
     * @public
     * @type function
     * @default function
     */
    @Input()
    public get textAccessor(): (value: string) => string {
        return this._textAccessor;
    }

    public set textAccessor(value: (value: string) => string) {
        this._textAccessor = value;
    }

    /**
     * Returns the `classes` property.
     *
     * @public
     * @readonly
     */
    @HostBinding('class')
    public get classes(): string {
        return HostBindingBuilder.buildClasses({
            [`${this._color}`]: true,
        });
    }

    // #endregion

}
