// #region Imports

import { NgModule, Pipe, PipeTransform } from '@angular/core';

// #endregion

/**
 * Transforms a `null` or `undefined` value to an `N/A` string.
 *
 * @public
 */
@Pipe({
    name: 'na',
})
export class NotAvailablePipe implements PipeTransform {

    // #region Methods

    public transform<T>(value: T): string | T {
        if (value === null || value === undefined || String(value) === '') {
            return '---';
        }

        return value;
    }

    // #endregion

}

@NgModule({
    declarations: [NotAvailablePipe],
    exports: [NotAvailablePipe],
})
// @ts-ignore
export class NotAvailablePipeModule {
}
