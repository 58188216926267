// #region Imports

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

// #endregion

/**
 * The `PagePreContentComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-page-pre-content',
    templateUrl: './PagePreContentComponent.html',
    styleUrls: ['./PagePreContentComponent.scss'],
    exportAs: 'l7PageContent',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagePreContentComponent {

    // #region Fields

    private _sticky: boolean;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `PagePreContentComponent` class.
     *
     * @public
     */
    public constructor() {
        this._sticky = false;
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `sticky` property.
     *
     * @public
     */
    @Input()
    @HostBinding('class.sticky')
    public get sticky(): boolean {
        return this._sticky;
    }

    public set sticky(value: boolean) {
        this._sticky = value;
    }

    // #endregion

}
