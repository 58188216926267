import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: '[l7CarouselItem]',
})
export class CarouselItemDirective {

    constructor(public tpl: TemplateRef<any>) {
    }

}
