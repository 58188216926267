// #region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { TextBlockModule } from '../TextBlock/TextBlockModule';
import { FilterClearAllDirective } from './FilterClearAllDirective';
import { FilterComponent } from './FilterComponent';
import { FilterPartDirective } from './FilterPartDirective';

// #endregion

/**
 * The `FilterModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatExpansionModule,
        FlexLayoutModule,
        TextBlockModule,
        MatRippleModule,
        MatBadgeModule,
    ],
    declarations: [
        FilterComponent,
        FilterClearAllDirective,
        FilterPartDirective,
    ],
    exports: [
        FilterComponent,
        FilterClearAllDirective,
        FilterPartDirective,
    ],
})
export class FilterModule {
}
