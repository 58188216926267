// #region Imports

import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

// #endregion

/**
 * Transforms a value to an observable sequence.
 *
 * @public
 */
@Pipe({
    name: 'of',
})
export class OfPipe implements PipeTransform {

    // #region Methods

    public transform<T>(value: T): Observable<T> {
        return value instanceof Observable
            ? value
            : of(value);
    }

    // #endregion

}

@NgModule({
    declarations: [OfPipe],
    exports: [OfPipe],
})
export class OfPipeModule {
}
