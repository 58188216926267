// #region Imports

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Directive, EmbeddedViewRef, OnDestroy, OnInit, TemplateRef, ViewContainerRef, isDevMode } from '@angular/core';
import { Subscription } from 'rxjs';
import { BREAK_POINTS } from './BreakPoints';

// #endregion

/**
 * @private
 */
interface IBreakpointObserverContext {
    $implicit: (alias: string | Array<string>) => boolean;
}

/**
 * @public
 */
@Directive({
    selector: '[cdkBreakpoint], [cdkObserveBreakpoint], [l7observeBreakpoint]',
})
export class BreakpointObserverDirective implements OnInit, OnDestroy {

    // #region Fields

    private readonly _vcr: ViewContainerRef;
    private readonly _templateRef: TemplateRef<IBreakpointObserverContext>;
    private readonly _cdr: ChangeDetectorRef;
    private readonly _breakpointObserver: BreakpointObserver;

    private _breakpointObserverSubscription: Subscription;
    private _view: EmbeddedViewRef<IBreakpointObserverContext>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `BreakpointObserverDirective` class.
     *
     * @public
     */
    public constructor(vcr: ViewContainerRef, templateRef: TemplateRef<IBreakpointObserverContext>, cdr: ChangeDetectorRef, breakpointObserver: BreakpointObserver) {
        this._vcr = vcr;
        this._templateRef = templateRef;
        this._cdr = cdr;
        this._breakpointObserver = breakpointObserver;
        this._breakpointObserverSubscription = null;
        this._view = null;
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public ngOnInit(): void {
        this.render();
    }

    /**
     * @public
     */
    public ngOnDestroy(): void {
        this._breakpointObserverSubscription?.unsubscribe();
    }

    /**
     * @private
     */
    private render(): void {
        if (this._view) {
            this._view.context.$implicit = this.getImplicitFn();
        } else {
            this._view = this._vcr.createEmbeddedView(this._templateRef, {
                $implicit: this.getImplicitFn(),
            });
        }
    }

    /**
     * @private
     */
    private getImplicitFn(): (key: string | Array<string>) => boolean {
        return (key) => {
            const keys = Array.isArray(key) ? key : [key];
            const mediaQuery = BREAK_POINTS.filter(x => keys.includes(x.alias)).map(x => x.mediaQuery);

            if (isDevMode() && mediaQuery.length === 0) {
                throw new Error(`The alias or aliases are ${keys.join(', ')} not defined.`);
            }

            const isMatched = this._breakpointObserver.isMatched(mediaQuery);

            this._breakpointObserverSubscription?.unsubscribe();
            this._breakpointObserverSubscription = this._breakpointObserver.observe(mediaQuery)
                .subscribe(x => this.onObserve(x));

            return isMatched;
        };
    }

    /**
     * @private
     */
    private onObserve(state: BreakpointState): void {
        this.render();
        this._cdr.markForCheck();
    }

    // #endregion

}
