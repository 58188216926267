// #region Imports

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ColorSet } from '../../Color';
import { HostBindingBuilder } from '../../HostBindingBuilder';

// #endregion

/**
 * The `TagComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-tag',
    templateUrl: './TagComponent.html',
    styleUrls: ['./TagComponent.scss'],
    exportAs: 'l7tag',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {

    // #region Fields

    private _text: string;
    private _color: ColorSet;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `SummaryComponent` class.
     *
     * @public
     */
    public constructor() {
        this._text = '';
        this._color = 'neutral';
    }

    // #endregion

    // #region Methods

    /**
     * Gets or sets the color set type value. This will applied as dom class property.
     *
     * @public
     * @type ColorSet | string
     * @default ColorSet.Default
     */
    @Input()
    public get color(): ColorSet | string {
        return this._color;
    }

    public set color(value: ColorSet | string) {
        this._color = value as ColorSet;
    }

    /**
     * Gets or sets the `text` property.
     *
     * @public
     */
    @Input()
    public get text(): string {
        return this._text;
    }

    public set text(value: string) {
        this._text = value;
    }

    /**
     * Returns the `classes` property.
     *
     * @public
     * @readonly
     */
    @HostBinding('class')
    public get classes(): string {
        return HostBindingBuilder.buildClasses({
            [`${this._color}`]: true,
        });
    }

    // #endregion

}
