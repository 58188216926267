// #region Imports

import { BrokerCustomizationService } from '@abcfinlab/api/contract';
import { IUserInfoDto } from '@abcfinlab/api/login';
import { TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { base64ToBlob } from '../../../../../apps/shell/src/app/helper/presentation.helper';
import { DialogService } from '../../../../../apps/shell/src/app/private/services/dialog/dialog.service';
import { Globals } from '../../../../core/src/Globals';
import { LogoUploadComponent } from '../logo-upload/logo-upload.component';

// #endregion

/**
 * The presenter of the {@link ConfigurationOverviewView} view.
 *
 * @internal
 */
@UntilDestroy()
@Injectable()
export class ConfigurationOverviewViewPresenter {

    // #region Fields

    private readonly _brokerCustomizationService: BrokerCustomizationService;
    private readonly _busyBox: BusyBoxService;

    private readonly _logoURL$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _useCustomLogo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private _userInfo: IUserInfoDto;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `ConfigurationOverviewViewPresenter` class.
     *
     * @public
     */
    public constructor(brokerCustomizationService: BrokerCustomizationService, busyBox: BusyBoxService, activatedRoute: ActivatedRoute,
        private readonly _dialog: DialogService, private readonly translate: TranslationFacade, private readonly notification: ToastService) {
        this._brokerCustomizationService = brokerCustomizationService;
        this._busyBox = busyBox;
        activatedRoute.data.subscribe(x => this._userInfo = x.userInfo);
    }

    // #endregion

    // #region Properties
    /**
     * Returns the `logoImage` property.
     *
     * @public
     * @readonly
     */
    public get logoImageURL(): Observable<string> {
        return this._logoURL$.asObservable();
    }

    public get isCustomLogoUsed(): Observable<boolean> {
        return this._useCustomLogo$.asObservable();
    }
    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this._busyBox.show(undefined, '', this._brokerCustomizationService.getLogo({ groupId: this._userInfo.groupId }))
            .subscribe((x) => {
                const blob = base64ToBlob(x.base64EncodedLogo);
                const imageURL = URL.createObjectURL(blob);
                this._logoURL$.next(imageURL);
                this._useCustomLogo$.next(x.customized);
            });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    public showContractDraft(): void {
        this._brokerCustomizationService.getContractDraft().pipe().subscribe((draftPdf) => {
            const draftPdfUrl = URL.createObjectURL(draftPdf);
            window.open(draftPdfUrl, '_blank');
        }, (error) => {
            this.notification.showError(this.translate.instant('error.generic_error'));
        });
    }

    public openLogoUploadDialog(uploadedFile: any, defaultLogoConfig: typeof Globals.defaultLogoConfig): void {
        const dialogRef = this._dialog.openDialog(LogoUploadComponent, {}, { imagetoCrop: uploadedFile, logoConfig: defaultLogoConfig });
        dialogRef.afterClosed().pipe(
            untilDestroyed(this),
        ).subscribe((result) => {
            if (result) {
                this._brokerCustomizationService.postLogo({ groupId: this._userInfo.groupId, body: { logo: result.croppedImageFile } })
                    .pipe(untilDestroyed(this))
                    .subscribe((res) => {
                        this.initialize();
                        this.notification.showSuccess(this.translate.instant('global.save_successful'));
                    }, (_error) => {
                        this._useCustomLogo$.next(false);
                        this.notification.showError(this.translate.instant('error.generic_error'));
                    });
            }
        });
    }

    public resetLogo(): void {
        this._busyBox.show(undefined, '', this._brokerCustomizationService.deleteLogo({ groupId: this._userInfo.groupId }))
            .subscribe((x) => {
                this.initialize();
            });
    }
    // #endregion

}
