// #region Imports

import { Directive, Input, NgModule } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators } from '@angular/forms';

// #endregion

/**
 * @public
 */
@Directive({
    selector: '[l7Max]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxValidatorDirective, multi: true }],
})
export class MaxValidatorDirective implements Validator {

    @Input('l7Max')
    public max: number;

    public validate(control: AbstractControl): ValidationErrors | null {
        return this.max
            ? Validators.max(this.max)(control)
            : null;
    }

}

/**
 * @public
 */
@NgModule({
    declarations: [
        MaxValidatorDirective,
    ],
    exports: [
        MaxValidatorDirective,
    ],
})
export class MaxValidatorModule { }
