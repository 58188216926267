// #region Imports

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

// #endregion

/**
 * The `DialogHeaderComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-dialog-header',
    templateUrl: 'DialogHeaderComponent.html',
    styleUrls: ['DialogHeaderComponent.scss'],
    exportAs: 'l7DialogHeader',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {

    // #region Fields

    private readonly _closed: EventEmitter<void>;

    private _title: string;
    private _titleIcon: string;
    private _titleIconColor: string;
    private _subTitle: string;
    private _supportiveTitle: string;
    private _closeable: boolean;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `DialogHeaderComponent` class.
     *
     * @public
     */
    public constructor() {
        this._title = '';
        this._titleIcon = '';
        this._titleIconColor = 'primary';
        this._subTitle = '';
        this._supportiveTitle = '';
        this._closeable = false;
        this._closed = new EventEmitter();
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `title` property.
     *
     * @public
     */
    @Input()
    public get title(): string {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
    }

    /**
     * Gets or sets the `titleIcon` property.
     *
     * @public
     */
    @Input()
    public get titleIcon(): string {
        return this._titleIcon;
    }

    public set titleIcon(value: string) {
        this._titleIcon = value;
    }

    /**
     * Gets or sets the `titleIconColor` property.
     *
     * @public
     */
    @Input()
    public get titleIconColor(): string {
        return this._titleIconColor;
    }

    public set titleIconColor(value: string) {
        this._titleIconColor = value;
    }

    /**
     * Gets or sets the `subTitle` property.
     *
     * @public
     */
    @Input()
    public get subTitle(): string {
        return this._subTitle;
    }

    public set subTitle(value: string) {
        this._subTitle = value;
    }

    /**
     * Gets or sets the `supportiveTitle` property.
     *
     * @public
     */
    @Input()
    public get supportiveTitle(): string {
        return this._supportiveTitle;
    }

    public set supportiveTitle(value: string) {
        this._supportiveTitle = value;
    }

    /**
     * Gets or sets the `hasBackNavigation` property.
     *
     * @public
     */
    @Input()
    public get closeable(): boolean {
        return this._closeable;
    }

    public set closeable(value: boolean) {
        this._closeable = value;
    }

    /**
     * Called when <ACTION>.
     *
     * @public
     * @readonly
     * @eventProperty
     * @type EventEmitter<void>
     */
    @Output()
    public get closed(): EventEmitter<void> {
        return this._closed;
    }

    // #endregion

}
