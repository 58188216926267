// #region Imports

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

// #endregion

/**
 * The `PageComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-page',
    templateUrl: './PageComponent.html',
    styleUrls: ['PageComponent.scss'],
    exportAs: 'l7Page',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent {

    // #region Fields

    private _isEmpty: boolean;

    private _isEmptyText: string;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `PageComponent` class.
     *
     * @public
     */
    public constructor() {
        this._isEmpty = false;
        this._isEmptyText = 'global.emptyList';
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `isEmpty` property.
     *
     * @public
     */
    @Input()
    public get isEmpty(): boolean {
        return this._isEmpty;
    }

    public set isEmpty(value: boolean) {
        this._isEmpty = value;
    }

    /**
     * Gets or sets the `isEmptyText` property.
     *
     * @public
     */
    @Input()
    public get isEmptyText(): string {
        return this._isEmptyText;
    }

    public set isEmptyText(value: string) {
        this._isEmptyText = value;
    }
    // #endregion

}
