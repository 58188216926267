// #region Imports

import { NgModule, Pipe, PipeTransform } from '@angular/core';

// #endregion

/**
 * Transforms a `string` to a tel href `string`.
 * @public
 */
@Pipe({
    name: 'mailto',
})
export class MailToPipe implements PipeTransform {

    // #region Methods

    public transform(value?: string): string {
        return value ? `mailto:${value}` : '';
    }

    // #endregion

}

/**
 * The `MailToPipeModule` provides the {@link MailToPipe}.
 * @public
 */
@NgModule({
    declarations: [MailToPipe],
    exports: [MailToPipe],
})
export class MailToPipeModule {
}
