// #region Imports

import { ICalculationDto, IInsuranceTypeDto } from '@abcfinlab/api/global';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

// #endregion

/**
 * Transforms a `ISepaMandateStatusDto` to the related icon name or css color class.
 * @public
 */
@Pipe({
    name: 'insuranceTypeIcon',
})
export class InsuranceTypeIconPipe implements PipeTransform {

    // #region Ctor

    public constructor() {
    }

    // #endregion

    // #region Methods

    public transform(value: ICalculationDto, type: 'icon' | 'color'): string {
        switch (value.insurance_type) {
            case IInsuranceTypeDto.Standard:
                if (type === 'color') {
                    if (value.insurance_value > value.default_insurance_value) {
                        return 'success';
                    } else if (value.insurance_value < value.default_insurance_value) {
                        return 'danger';
                    } else {
                        return '';
                    }
                } else if (type === 'icon') {
                    if (value.insurance_value > value.default_insurance_value) {
                        return 'Arrow_Top';
                    } else if (value.insurance_value < value.default_insurance_value) {
                        return 'Arrow_Down';
                    } else {
                        return '';
                    }
                } else {
                    break;
                }
            case IInsuranceTypeDto.Pro100:
            case IInsuranceTypeDto.No:
            default:
                break;
        }

        throw new Error('Argument type is invalid.');
    }

    // #endregion

}

/**
 * The `InsuranceTypeIconPipeModule` provides the {@link InsuranceTypeIconPipe}.
 * @public
 */
@NgModule({
    declarations: [
        InsuranceTypeIconPipe,
    ],
    exports: [
        InsuranceTypeIconPipe,
    ],
})
export class InsuranceTypeIconPipeModule {
}
