// #region Imports

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBusyBoxComponentData } from './IBusyBoxComponentData';
// #endregion

@Component({
    selector: 'l7-busy-box',
    templateUrl: './BusyBoxComponent.html',
    styleUrls: ['./BusyBoxComponent.scss'],
})
export class BusyBoxComponent {

    // #region Fields

    private readonly _data: any;

    // #endregion

    // #region Ctor

    public constructor(public dialogRef: MatDialogRef<BusyBoxComponent, void>, @Inject(MAT_DIALOG_DATA) data: IBusyBoxComponentData) {
        this._data = data;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `data` property.
     *
     * @public
     * @readonly
     */
    public get data(): IBusyBoxComponentData {
        return this._data;
    }

    // #endregion

    // #region Methods

    public onClose(): void {
        this.dialogRef.close();
    }

    // #endregion

}
