// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { LogoutViewPresenter } from './LogoutViewPresenter';

// #endregion

/**
 * The `LogoutView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-logout-view',
    templateUrl: './LogoutView.html',
    styleUrls: ['./LogoutView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LogoutViewPresenter,
    ],
})
export class LogoutView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: LogoutViewPresenter;

    // #endregion

    // #region Ctor

    /**
   * Constructs a new instance of the `LogoutView` class.
   *
   * @public
   */
    public constructor(presenter: LogoutViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
   * Returns the presenter of the `LogoutView`
   *
   * @public
   * @readonly
   */
    public get presenter(): LogoutViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
   * @internal
   */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
   * @internal
   */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
