import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'l7-custom-form-control',
    templateUrl: './CustomFormControlComponent.html',
    styleUrls: ['./CustomFormControlComponent.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CustomFormControlComponent,
        },
    ],
})
export class CustomFormControlComponent implements ControlValueAccessor {

    onChange = () => {};

    onTouched = () => {};

    touched = false;

    disabled = true;
    public writeValue(value: number): void {

    }

    public registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    public markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

}

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        CustomFormControlComponent,
    ],
    exports: [
        CustomFormControlComponent,
    ],
    // providers: [],
})
export class CustomFormControlModule { }
