/* libs/ui/src/Controls/Components/Tile/TileComponent.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host {
  display: block;
  cursor: pointer;
  z-index: 0;
  position: relative;
  border-radius: 4px;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid transparent;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  outline: none;
}
:host > div {
  cursor: pointer;
  padding: 16px;
  border-radius: 4px;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid transparent;
  box-shadow:
    0px 2px 1px -1px rgba(26, 35, 45, 0.2),
    0px 1px 1px 0px rgba(26, 35, 45, 0.14),
    0px 1px 3px 0px rgba(26, 35, 45, 0.12);
}
:host:active > div,
:host:focus > div {
  border: 1px solid #1b7e9a;
}
:host:hover > div {
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1490196078), 0px 1px 2px 0px rgba(0, 0, 0, 0.3019607843);
  border: 1px solid #1b7e9a;
}
:host.readonly > div {
  border-color: rgba(48, 59, 69, 0.12);
  cursor: default;
  box-shadow: none;
}
:host.disabled > div {
  border-color: rgba(48, 59, 69, 0.12);
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
  box-shadow: none;
}
:host.selected > div {
  border-color: #1b7e9a;
}
:host.inline > div {
  margin: 6px;
}
/*# sourceMappingURL=TileComponent.css.map */
