import { Component, Input } from '@angular/core';
import { iconMapByStatus, colorMapByStatus } from './boni-check-icon.config';
import { ILeasingQuoteCreditRatingDto } from '@abcfinlab/api/global';

@Component({
    selector: 'l7-boni-check-icon',
    templateUrl: './boni-check-icon.component.html',
    styleUrls: ['./boni-check-icon.component.scss'],
})
export class BoniCheckIconComponent {

    @Input() public loading: boolean = false;
    @Input() public status: ILeasingQuoteCreditRatingDto = null;
    public iconColorMap: Map<string, string> = colorMapByStatus;
    public iconMap: Map<string, string> = iconMapByStatus;

    public get showSpinner(): boolean {
        return this.loading || this.status === ILeasingQuoteCreditRatingDto.Pending;
    }

    public get iconColor(): string {
        return this.iconColorMap.get(this.status);
    }

    public get icon(): string {
        return this.iconMap.get(this.status);
    }

    public get badgeContent(): string | null {
        return this.status === ILeasingQuoteCreditRatingDto.ApprovedWithConditions
            || this.status === ILeasingQuoteCreditRatingDto.ApprovedWithConditionsFulfilled
            ? 'A'
            : null;
    }

}
