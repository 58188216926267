import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TraceparentHttpInterceptor implements HttpInterceptor {

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        req = req.clone({
            headers: req.headers
                .set('X-B3-TraceId', this.randomUUID())
                .set('X-B3-SpanId', this.randomUUID()),
        });

        return next.handle(req);
    }

    /**
     * Generates a random UUID with fallback.
     *
     * @private
     */
    private randomUUID(): string {
        return 'randomUUID' in crypto
            ? crypto.randomUUID()
            : this.generateUUID();
    }

    /**
     * Generates a UUID version 4 string.
     *
     * @private
     * @returns {string} The generated UUID.
     */
    private readonly generateUUID = (): string => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
            const rand = Math.random() * 16 | 0;
            const value = char === 'x' ? rand : (rand & 0x3 | 0x8);
            return value.toString(16);
        });
    };

}
