import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SwitchViewMode } from '@abcfinlab/core';
import { Store } from '@ngxs/store';

/**
 * The `BreakpointService` class.
 * @description:
 * According to our defined screensizes we have to support the actual dimensions of:
 * - Desktop: 1920x1080px
 * - Tablet: 1024x768px
 * - Mobile: 360x800px
 * For further Information pls see at the moment: https://abcfinlab.atlassian.net/browse/LEASA-7664
 *
 * @public
 */
export enum DevicesByWidth {
    XSMALL = 'extra small',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    XLARGE = 'extra large',
}

@Injectable()
export class BreakpointService {

    private readonly _deviceTypeByWidth$ = new BehaviorSubject<DevicesByWidth>(null);

    private readonly _mobileViewport$: Subject<boolean> = new BehaviorSubject(false);
    private readonly _tabletViewport$: Subject<boolean> = new BehaviorSubject(false);
    private readonly _desktopViewport$: Subject<boolean> = new BehaviorSubject(false);
    public isMobileViewport$: Observable<boolean> = this._mobileViewport$.asObservable();
    public isTabletViewport$: Observable<boolean> = this._tabletViewport$.asObservable();
    public isDesktopViewport$: Observable<boolean> = this._desktopViewport$.asObservable();
    public deviceTypeByWidth$: Observable<DevicesByWidth> = this._deviceTypeByWidth$.asObservable();

    constructor(private readonly _bpo: BreakpointObserver, private readonly _store: Store) {
        this.init();
    }

    public init(): void {
        this._bpo.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe((state) => {
            if (state.matches) {
                this._mobileViewport$.next(false);
                this._desktopViewport$.next(true);
                // this._store.dispatch(new SwitchViewMode(false));
            } else {
                this._mobileViewport$.next(true);
                this._desktopViewport$.next(false);
                //   this._store.dispatch(new SwitchViewMode(true));
            }
        });

        this._bpo.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge,
        ]).subscribe((state) => {
            if (state.breakpoints[Breakpoints.XSmall]) {
                this._deviceTypeByWidth$.next(DevicesByWidth.XSMALL);
                // this._desktopViewport$.next(false);
            } else if (state.breakpoints[Breakpoints.Small]) {
                this._deviceTypeByWidth$.next(DevicesByWidth.SMALL);
                // this._desktopViewport$.next(false);
            } else if (state.breakpoints[Breakpoints.Medium]) {
                this._deviceTypeByWidth$.next(DevicesByWidth.MEDIUM);
            } else if (state.breakpoints[Breakpoints.Large]) {
                this._deviceTypeByWidth$.next(DevicesByWidth.LARGE);
                this._desktopViewport$.next(true);
            } else {
                this._deviceTypeByWidth$.next(DevicesByWidth.XLARGE);
                this._desktopViewport$.next(true);
            }
        });
    }

}
