import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    Input,
    OnChanges,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { CarouselItemDirective } from '../../Directives/CarouselItemDirective';

@UntilDestroy()
@Component({
    selector: 'l7-carousel',
    templateUrl: './CarouselComponent.html',
    styleUrls: ['./CarouselComponent.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements AfterViewInit, OnChanges {

    // #region Fields

    private readonly _cdr: ChangeDetectorRef;
    @ViewChild('carousel')
    private readonly _carousel: ElementRef;

    @ViewChildren('carouselItem', { read: ElementRef })
    private readonly _itemsElements: QueryList<ElementRef>;

    private readonly _carouselWrapperStyle: BehaviorSubject<string> = new BehaviorSubject<string>('0 1 auto');
    private _carouselWidth: number;
    private _isTransitioning: boolean = false;
    private _current: number = 0;
    public numItems: number = 0;
    public translationX: number = 300;

    @ContentChildren(CarouselItemDirective)
    public items: QueryList<CarouselItemDirective>;
    // #endregion

    // #region Ctor

    public constructor(cdr: ChangeDetectorRef) {
        this._cdr = cdr;
    }

    // #endregion

    // #region Properties
    @Input() public showControls: boolean = true;
    @Input() public maxShowedItems: number = 5;
    @Input() public gapBetweenItems: number = 24;
    @Input() public elemWidth: number = 200;
    @Input() public placeholder: string = '';

    public get carouselWrapperStyle(): Observable<string> {
        return this._carouselWrapperStyle.asObservable();
    }

    // #endregion

    // #region Methods
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.items) {
            this.updateNumItems();
        }
        if (changes.maxShowedItems) {
            this._carouselWidth = (this.maxShowedItems * (this.elemWidth + this.gapBetweenItems)) - this.gapBetweenItems + 2;
            this._carouselWrapperStyle.next(`0 1 ${this._carouselWidth}px`);
        }
    }

    public next(): void {
        if (!this._isTransitioning) {
            this._isTransitioning = true;
            this._carousel.nativeElement.style.transition = 'transform 0.2s ease';
            this._carousel.nativeElement.style.transform = `translateX(-${this.translationX}px)`;

            setTimeout(() => {
                this._isTransitioning = false;
                this._current++;
                if (this._current >= this.numItems) {
                    this._current = 0;
                }
                this.reorderItems();
            }, 200);
        }
    }

    public prev(): void {
        if (!this._isTransitioning) {
            this._isTransitioning = true;

            // Reorder items before applying the transition
            this._current--;
            if (this._current < 0) {
                this._current = this.numItems - 1;
            }
            this.reorderItems();

            // Apply the transition
            this._carousel.nativeElement.style.transition = 'transform 0.2s ease';
            this._carousel.nativeElement.style.transform = `translateX(${this.translationX}px)`;

            setTimeout(() => {
                this._isTransitioning = false;
                // Reset the transform to the initial state
                this._carousel.nativeElement.style.transition = 'none';
                this._carousel.nativeElement.style.transform = 'translateX(0)';
                this._cdr.detectChanges();
            }, 200);
        }
    }

    public ngAfterViewInit(): void {
        const eleWidth = this.elemWidth ? this.elemWidth : this._itemsElements?.first?.nativeElement.firstChild.offsetWidth;
        this.translationX = eleWidth + this.gapBetweenItems;
        this._carouselWidth = (this.maxShowedItems * (eleWidth + this.gapBetweenItems)) - this.gapBetweenItems + 2;
        this._carouselWrapperStyle.next(`0 1 ${this._carouselWidth}px`);
        this.numItems = this._itemsElements.length;
        this.setupSubscriptions();
        this.updateNumItems();
    }

    private setupSubscriptions(): void {
        this._itemsElements.changes.subscribe(() => {
            this.updateNumItems();
        });

        this.items.changes.subscribe(() => {
            this.updateNumItems();
        });
    }

    private updateNumItems(): void {
        setTimeout(() => {
            this.numItems = this._itemsElements.length;
            this.resetCarousel();
        }, 100);
    }

    private resetCarousel(): void {
        this._current = this.numItems > this.maxShowedItems ? this.numItems - 1 : this.numItems;
        this.reorderItems();
    }

    private reorderItems(): void {
        const items: Array<HTMLElement> = Array.from(this._carousel.nativeElement.children);
        // if the current index goes beyond the last item, it wraps back to the first item
        const order = this._current % this.numItems;
        items.forEach((item, index) => {
            // The reordering logic using this circular index ensures that the current visible item is always correctly positioned at the start, and subsequent items follow in the correct order
            const newOrder = (index - order + this.numItems) % this.numItems;
            item.style.order = newOrder.toString();
        });

        this._carousel.nativeElement.style.transition = 'none';
        this._carousel.nativeElement.style.transform = 'translateX(0)';
        this._cdr.detectChanges();
    }

    // #endregion

}
