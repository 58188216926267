// #region Imports

import { TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, ToastService } from '@abcfinlab/ui';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAuthInfoDto } from '../../../api/src/Login/Index';
import { AuthService } from '../Services/AuthService';
import { UserService } from '../Services/UserService';

// #endregion

/**
 * The presenter of the {@link AuthorizeView} view.
 *
 * @internal
 */
@Injectable()
export class AuthorizeViewPresenter {

    // #region Fields

    private readonly _formBuilder: UntypedFormBuilder;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _toastService: ToastService;
    private readonly _authService: AuthService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _document: Document;
    private readonly _formSubject: BehaviorSubject<UntypedFormGroup | null>;
    private readonly _isBusySubject: BehaviorSubject<boolean>;
    private readonly _isInterveningNeededSubject: BehaviorSubject<boolean>;
    private readonly _accountsSubject: BehaviorSubject<Array<IAuthInfoDto>>;
    private readonly _userService: UserService;
    private readonly _router: Router;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `AuthorizeViewPresenter` class.
     *
     * @public
     */
    public constructor(
        formBuilder: UntypedFormBuilder,
        busyBoxService: BusyBoxService,
        toastService: ToastService,
        authService: AuthService,
        translationFacade: TranslationFacade,
        router: Router, @Inject(DOCUMENT) document: any,
        userService: UserService) {
        this._formBuilder = formBuilder;
        this._busyBoxService = busyBoxService;
        this._toastService = toastService;
        this._authService = authService;
        this._translationFacade = translationFacade;
        this._document = document;
        this._formSubject = new BehaviorSubject<UntypedFormGroup | null>(null);
        this._isBusySubject = new BehaviorSubject<boolean>(false);
        this._isInterveningNeededSubject = new BehaviorSubject<boolean>(false);
        this._accountsSubject = new BehaviorSubject<Array<IAuthInfoDto>>([]);
        this._userService = userService;
        this._router = router;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): Observable<UntypedFormGroup> {
        return this._formSubject.asObservable();
    }

    /**
     * Returns the `isBusy` property.
     *
     * @public
     * @readonly
     */
    public get isBusy(): Observable<boolean> {
        return this._isBusySubject.asObservable();
    }

    /**
     * Returns the `isInterveningNeeded` property.
     *
     * @public
     * @readonly
     */
    public get isInterveningNeeded(): Observable<boolean> {
        return this._isInterveningNeededSubject.asObservable();
    }

    /**
     * Returns the `accounts` property.
     *
     * @public
     * @readonly
     */
    public get accounts(): Observable<Array<IAuthInfoDto>> {
        return this._accountsSubject.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @public
     */
    public initialize(): void {
        this._authService.getSession().pipe().subscribe((x) => {
            this._userService.getUserInfo().subscribe(() => this._router.navigate(['presentation'], { replaceUrl: true }).then());
        });

        const form = this._formBuilder.group({
            email: ['', [
                Validators.email,
                Validators.required,
            ]],
        });

        this._formSubject.next(form);
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @public
     */
    public dispose(): void {
    }

    /**
     * @internal
     */
    public onSubmit(): void {
        if (this._formSubject.value.invalid) {
            this._toastService.show(this._translationFacade.translate('auth.submit.invalid'), 'danger');
            return;
        }

        this._isInterveningNeededSubject.next(false);

        this._busyBoxService.show('', '', this._authService.auth(this._formSubject.value.getRawValue().email)).subscribe((x) => {
            this._accountsSubject.next(x);

            if (x.length > 1) {
                this._isInterveningNeededSubject.next(true);
            } else if (x.length === 1) {
                this.onAccount(x[0]);
            } else {
                this._toastService.show(this._translationFacade.translate('auth.submit.unknownEmail'), 'danger');
            }
        }, (error) => {
            this._toastService.show(error, 'danger');
        });
    }

    /**
     * @internal
     */
    public onAccount(account: IAuthInfoDto): void {
        // const encodedUsername = encodeURIComponent(this._formSubject.value.getRawValue().email);
        // const uri = account.uri.concat(`&username=${encodedUsername}`);
        this._document.location.href = account.url;
    }

    /**
     * @internal
     */
    public onBack(): void {
        this._accountsSubject.next([]);
        this._isInterveningNeededSubject.next(false);
        this._formSubject.value.reset();
    }

    // #endregion

}
