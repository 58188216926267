// #region Imports

import { objectEquals } from '@abcfinlab/core';
import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, Input } from '@angular/core';
import { FilterClearAllDirective } from './FilterClearAllDirective';
import { FilterCondition } from './FilterCondition';

// #endregion

/**
 * The `FilterComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-filter',
    templateUrl: 'FilterComponent.html',
    styleUrls: ['FilterComponent.scss'],
    exportAs: 'l7DialogHeader',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent {

    // #region Fields

    @ContentChild(FilterClearAllDirective, { static: false, read: ElementRef })
    private readonly _clearAllElement: ElementRef<HTMLButtonElement>;

    private _title: string;
    private _expandable: boolean;
    private _condition: FilterCondition<unknown> | null;
    private _defaultCondition: FilterCondition<unknown> | null;
    private _conditionComparer: (other: FilterCondition<unknown> | null) => boolean | null;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `FilterComponent` class.
     *
     * @public
     */
    public constructor() {
        this._title = '';
        this._expandable = false;
        this._condition = null;
        this._defaultCondition = null;
        this._conditionComparer = null;
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `title` property.
     *
     * @public
     */
    @Input()
    public get title(): string {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
    }

    /**
     * Gets or sets the `expandable` property.
     *
     * @public
     */
    @Input()
    public get expandable(): boolean {
        return this._expandable;
    }

    public set expandable(value: boolean) {
        this._expandable = value;
    }

    /**
     * Gets or sets the `condition` property.
     *
     * @public
     */
    @Input()
    public get condition(): FilterCondition<unknown> | null {
        return this._condition;
    }

    public set condition(value: FilterCondition<unknown> | null) {
        this._condition = value;
    }

    /**
     * Gets or sets the `defaultCondition` property.
     *
     * @public
     */
    @Input()
    public get defaultCondition(): FilterCondition<unknown> | null {
        return this._defaultCondition;
    }

    public set defaultCondition(value: FilterCondition<unknown> | null) {
        this._defaultCondition = value;
    }

    /**
     * Gets or sets the `conditionComparer` property.
     *
     * @public
     */
    @Input()
    public get conditionComparer(): (other: FilterCondition<unknown> | null) => boolean {
        return this._conditionComparer;
    }

    public set conditionComparer(value: (other: FilterCondition<unknown> | null) => boolean) {
        this._conditionComparer = value;
    }

    // #endregion

    // #region Methods

    public collectChangesCount(): number {
        let count = 0;

        Object.keys(this.defaultCondition).forEach((x) => {
            const isEqual = objectEquals(this.defaultCondition[x], this.condition[x]);
            count = isEqual ? count : count = count + 1;
        });

        return count;
    }

    // #endregion

}
