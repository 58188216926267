import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * @deprecated
 */
export interface GenericDialogData {
    id: string;
    title?: string;
    image?: string;
    body?: string;
    positiveText?: string;
    negativeText?: string;
}

/**
 * @deprecated
 */
@Component({
    selector: 'l7-generic-dialog',
    templateUrl: './generic-dialog.component.html',
    styleUrls: ['./generic-dialog.component.scss'],
})
export class GenericDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<GenericDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericDialogData,
    ) { }

    onConfirm(result: boolean) {
        this.dialogRef.close(result);
    }

}
