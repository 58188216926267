// #region Imports

import { Directive, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CheckboxGroupComponent } from '../CheckboxGroupComponent';

// #endregion

/**
 * @public
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'l7-checkbox-group[formControlName], l7-checkbox-group[formControl], l7-checkbox-group[ngModel]',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxGroupControlValueAccessorDirective), multi: true },
    ],
})
export class CheckboxGroupControlValueAccessorDirective implements ControlValueAccessor, OnInit, OnDestroy {

    // #region Fields

    private readonly _element: CheckboxGroupComponent;

    private _changedSubscription: Subscription;
    private _onTouchedCallback: () => void;
    private _onChangeCallback: (_: any) => void;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `CheckboxGroupControlValueAccessorDirective` class.
     *
     * @public
     */
    public constructor(element: CheckboxGroupComponent) {
        this._element = element;
        this._changedSubscription = Subscription.EMPTY;
        this._onTouchedCallback = () => { };
        this._onChangeCallback = () => { };
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public ngOnInit(): void {
        this._changedSubscription = this._element.changed.subscribe(x => this.onChanged(x.values));
    }

    /**
     * @public
     */
    public ngOnDestroy(): void {
        this._changedSubscription.unsubscribe();
    }

    /**
     * @public
     */
    public writeValue(value: any): void {
        this._element.values = value;
    }

    /**
     * @public
     */
    public setDisabledState(isDisabled: boolean): void {
        this._element.disabled = isDisabled;
    }

    /**
     * @public
     */
    public registerOnChange(fn: (_: any) => void): void {
        this._onChangeCallback = fn;
    }

    /**
     * @public
     */
    public registerOnTouched(fn: () => void): void {
        this._onTouchedCallback = fn;
    }

    /**
     * @private
     */
    private onChanged(value: any): void {
        this._onChangeCallback(value);
    }

    // #endregion

}
