/* apps/shell/src/app/shared/components/top-bar-error/top-bar-error.component.scss */
:host .top-bar-error-container {
  position: absolute;
  left: 0;
  top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border-top: 1px solid #b81931;
  border-bottom: 1px solid #b81931;
  font-size: 12px;
  background-color: #ffffff;
}
@media screen and (min-width: 1024px) {
  :host .top-bar-error-container {
    flex-direction: row;
  }
}
@media screen and (min-width: 1280px) {
  :host .top-bar-error-container {
    font-size: 16px;
  }
}
:host .top-bar-error-container .headline,
:host .top-bar-error-container .error-message {
  text-align: center;
  color: #b81931;
}
:host .top-bar-error-container .error-message {
  font-weight: bold;
}
/*# sourceMappingURL=top-bar-error.component.css.map */
