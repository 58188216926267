export namespace Pattern {

    /**
     * This regular expression pattern is used to validate phone numbers in a variety of common formats.
     *
     * The pattern matches:
     * - An optional leading plus sign (`+`) for international dialing codes.
     * - A sequence of one or more digits.
     *
     * @public
     * @example
     * // Valid phone numbers:
     * // +1234567890
     * // 1234567890
     * // 123456789012
     *
     * @example
     * // Usage
     * const isValidPhoneNumber = Pattern.phoneNumber2.test('1234567890');
     * console.log(isValidPhoneNumber); // Output: true
     */
    export const phoneNumber: RegExp = /^\+?\d+$/;

}
