// #region Imports

import { Directive } from '@angular/core';

// #endregion

/**
 * The `FilterClearAllDirective` directive.
 *
 * @public
 */
@Directive({
    selector: 'button[l7FilterClearAll]',
    exportAs: 'l7FilterClearAll',
})
export class FilterClearAllDirective {

    // #region Ctor

    /**
     * Constructs a new instance of the `FilterClearAllDirective` class.
     *
     * @public
     */
    public constructor() {

    }

    // #endregion

}
