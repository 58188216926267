import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';

/** Error when invalid control is dirty, touched, or submitted. */
export class NotNullValeErrorStateMatcher implements ErrorStateMatcher {

    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;

        return !!(control && (control.invalid && control.value) && (control.dirty || control.touched || isSubmitted));
    }

}
