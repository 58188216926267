// #region Imports

import { AppConfig, SESSION_AVAILABLE_TOKEN, once } from '@abcfinlab/core';
import { UiStatesPreservationService } from '@abcfinlab/presentation';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Inject, Injectable } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { delay, first, map } from 'rxjs/operators';
import { LoadingService } from '../../../../apps/shell/src/app/shared/services/loading/loading.service';
import { AppbarAction } from '../Models/AppbarAction';
import { INavigationInfo } from '../Models/INavigationInfo';
import { IPresenterService } from '../Services/Interfaces/IPresenterService';
import { PresenterService } from '../Services/PresenterService';

// #endregion

/**
 * The presenter of the {@link PresenterView} view.
 *
 * @internal
 */
@Injectable()
export class PresenterViewPresenter {

    // #region Fields

    private readonly _presenterService: IPresenterService;
    private readonly _loadingService: LoadingService;
    private readonly _breakpointObserver: BreakpointObserver;
    private readonly _appConfig: AppConfig;
    private readonly _uiStatesPreservationService: UiStatesPreservationService;
    private readonly _navigationModeSubject: BehaviorSubject<MatDrawerMode>;
    private readonly _navigationIsOpenSubject: BehaviorSubject<boolean>;
    private readonly _navigationItemsSubject: BehaviorSubject<Array<INavigationInfo>>;
    private readonly _navigationIsCompactModeSubject: BehaviorSubject<boolean>;
    private readonly _navigationIsCompactModeForcedSubject: BehaviorSubject<boolean>;
    private readonly _actionItemsSubject: BehaviorSubject<Array<AppbarAction>>;
    private readonly _hasSessionSubject: BehaviorSubject<boolean>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `PresenterViewPresenter` class.
     *
     * @public
     */
    public constructor(presenterService: PresenterService, appConfig: AppConfig, breakpointObserver: BreakpointObserver,
        loadingService: LoadingService, uiStatesPreservationService: UiStatesPreservationService,
        @Inject(SESSION_AVAILABLE_TOKEN) sessionAvailable: Subject<void>) {
        this._presenterService = presenterService;
        this._loadingService = loadingService;
        this._breakpointObserver = breakpointObserver;
        this._appConfig = appConfig;
        this._uiStatesPreservationService = uiStatesPreservationService;

        this._navigationModeSubject = new BehaviorSubject('side');
        this._navigationIsOpenSubject = new BehaviorSubject(true);
        this._navigationItemsSubject = new BehaviorSubject([]);
        this._actionItemsSubject = new BehaviorSubject([]);
        this._navigationIsCompactModeSubject = new BehaviorSubject(false);
        this._navigationIsCompactModeForcedSubject = new BehaviorSubject(false);
        this._hasSessionSubject = new BehaviorSubject(false);

        sessionAvailable.pipe(first()).subscribe(() => {
            this._hasSessionSubject.next(true);
        });
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `stage` property.
     *
     * @public
     * @readonly
     */
    public get stage(): string {
        return this._appConfig.get('stage');
    }

    /**
     * Returns the `title` property.
     *
     * @public
     * @readonly
     */
    public get title(): Observable<string> {
        return this._presenterService.title;
    }

    /**
     * Returns the `isBusy` property.
     *
     * @public
     * @readonly
     */
    public get isBusy(): Observable<boolean> {
        return this._presenterService.isBusy;
    }

    /**
     * Returns the `isLoading` property.
     *
     * @public
     * @readonly
     */
    public get isLoading(): Observable<boolean> {
        return this._loadingService.getLoadingStatus();
    }

    /**
     * Returns the `navigationMode` property.
     *
     * @public
     * @readonly
     */
    public get navigationMode(): Observable<MatDrawerMode> {
        return this._navigationModeSubject.asObservable();
    }

    /**
     * Returns the `navigationIsOpen` property.
     *
     * @public
     * @readonly
     */
    public get navigationIsOpen(): Observable<boolean> {
        return this._navigationIsOpenSubject.asObservable();
    }

    /**
     * Returns the `navigationItems` property.
     *
     * @public
     * @readonly
     */
    public get navigationItems(): Observable<Array<INavigationInfo>> {
        return this._navigationItemsSubject.asObservable();
    }

    /**
     * Returns the `actionItems` property.
     *
     * @public
     * @readonly
     */
    public get actionItems(): Observable<Array<AppbarAction>> {
        return this._actionItemsSubject.asObservable();
    }

    /**
     * Returns the `navigationIsCompactMode` property.
     *
     * @public
     * @readonly
     */
    public get navigationIsCompactMode(): Observable<boolean> {
        return combineLatest([this._navigationIsCompactModeSubject, this._navigationIsCompactModeForcedSubject]).pipe(
            map(([x, y]) => x && y),
        );
    }

    /**
     * Returns the `hasSession` property.
     *
     * @public
     * @readonly
     */
    public get hasSession(): Observable<boolean> {
        return this._hasSessionSubject.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        once(this._uiStatesPreservationService.get<{ isCompact: boolean }>('layout'), (x) => {
            this._navigationIsCompactModeSubject.next(x?.isCompact ?? false);
        });

        this._breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium]).subscribe((x) => {
            // this is clear - we need this because of the display of the navigation.
            // for mobile - we use "over"
            // for other - we use "side"
            this._navigationModeSubject.next(x.matches ? 'over' : 'side');

            // x.matches = true - when the screen is mobile.
            //           = false - when the screen is not desktop.
            // this._navigationIsCompactModeSubject.next(shouldCompactMode);
            this._navigationIsOpenSubject.next(x.matches ? false : true);
            if (x.matches) {
                of(x.matches).pipe(
                    // we need this delay because of material side bar animation
                    delay(600),
                ).subscribe(() => {
                    this._navigationIsCompactModeForcedSubject.next(false);
                });
            } else {
                this._navigationIsCompactModeForcedSubject.next(true);
            }
        });

        this._presenterService.navigation.subscribe(x => this._navigationItemsSubject.next(x));
        this._presenterService.actions.subscribe(x => this._actionItemsSubject.next(x));
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    /**
     * @internal
     */
    public onToggleNavigationMode(): void {
        this._navigationIsCompactModeSubject.next(!this._navigationIsCompactModeSubject.value);
        this._uiStatesPreservationService.set('layout', { isCompact: this._navigationIsCompactModeSubject.value });
    }

    /**
     * @internal
     */
    public onHandleNavigation(sidenav: MatSidenav): void {
        if (this._navigationModeSubject.value === 'over') {
            void sidenav.toggle();
        }
    }

    // #endregion

}
