import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { ContactDTO } from '../../../../../apps/shell/src/app/models/ContactDTO.interface';
import { MatIconModule } from '@angular/material/icon';
import { IContactDto } from '@abcfinlab/api/contact';
import { IContactDto as IContactGlobal } from '@abcfinlab/api/global';

@Component({
    selector: 'l7-address-card',
    templateUrl: './address-card.component.html',
    styleUrls: ['./address-card.component.scss'],
})
export class AddressCardComponent {

    @Input() imageName: string;

    @Input() icon: string;

    @Input() iconContent: string;

    @Input() title: string;

    @Input() subTitle: string;

    @Input() contact: ContactDTO | IContactDto | IContactGlobal;

    @Input() contentHtml: string;

    @Input() flat: boolean;

    constructor() { }

}

@NgModule({
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
    ],
    declarations: [
        AddressCardComponent,
    ],
    exports: [
        AddressCardComponent,
    ],
    // providers: [],
})
export class AddressCardModule { }
