// #region Imports

import { NgModule, Pipe, PipeTransform } from '@angular/core';

// #endregion

@Pipe({
    name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {

    // #region Methods

    public transform(array: Array<any>, orderField: string, orderType: boolean): Array<string> {
        array.sort((a: any, b: any) => {
            const ae = a[orderField];
            const be = b[orderField];
            if (ae === undefined && be === undefined) {
                return 0;
            }
            if (ae === undefined && be !== undefined) {
                return orderType ? 1 : -1;
            }
            if (ae !== undefined && be === undefined) {
                return orderType ? -1 : 1;
            }
            if (ae === be) {
                return 0;
            }
            return orderType
                ? (ae.toString().toLowerCase() > be.toString().toLowerCase() ? -1 : 1)
                : (be.toString().toLowerCase() > ae.toString().toLowerCase() ? -1 : 1);
        });
        return array;
    }

    // #endregion

}

@NgModule({
    declarations: [OrderByPipe],
    exports: [OrderByPipe],
})
export class OrderByPipeModule {
}
