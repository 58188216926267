import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
    selector: 'input[l7SelectOnDblClick]',
    exportAs: 'l7SelectOnDblClick',
})
export class SelectOnDblClickDirective implements OnInit, OnDestroy {

    // #region Fields

    private readonly _element: ElementRef<HTMLInputElement>;

    private _dblClickSubscription: Subscription | null;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `SelectAllDirective` class.
     *
     * @public
     */
    public constructor(element: ElementRef) {
        this._element = element;
        this._dblClickSubscription = null;
    }

    public ngOnInit(): void {
        this._dblClickSubscription = fromEvent(this._element.nativeElement, 'dblclick').subscribe(() => {
            this._element.nativeElement.select();
        });
    }

    public ngOnDestroy(): void {
        this._dblClickSubscription.unsubscribe();
    }

    // #endregion

}
