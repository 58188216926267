import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'l7-compare-to',
    templateUrl: './CompareToComponent.html',
    styleUrls: ['./CompareToComponent.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareToComponent implements OnInit {

    private readonly _showValue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    @Input() valueToShow: string;
    @Input() valueToCompare: string;

    public get showValue(): Observable<boolean> {
        return this._showValue.asObservable();
    }

    ngOnInit() {
        if (this.valueToCompare === undefined || this.valueToCompare === null || this.valueToShow !== this.valueToCompare) {
            this._showValue.next(true);
        } else {
            this._showValue.next(false);
        }
    }

}
