import { ContractsDocumentService, IContractDocumentDto, IDocumentTypeDto } from '@abcfinlab/api/contract';
import { IUserInfoDto } from '@abcfinlab/api/login';
import { isUserInfoLogins, UserService } from '@abcfinlab/auth';
import { TranslationFacade } from '@abcfinlab/core';
import { ToastService } from '@abcfinlab/ui';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomFile } from '../file-uploader/file-uploader.component';
@UntilDestroy()
@Component({
    selector: 'l7-upload-documents',
    templateUrl: './upload-documents.component.html',
    styleUrls: ['./upload-documents.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDocumentsComponent implements OnInit {

    @Input() set navContractNr(value: string) {
        if (value) {
            this.contractNumber = value;
            this._contractsDocumentsService.getUploadCategoriesByContractNumber({ contractNumber: value })
                .pipe(untilDestroyed(this))
                .subscribe((x) => {
                    x.forEach((category) => {
                        this.addCategory(category);
                    });
                });
        }
        this._cdr.detectChanges();
    }

    @Input() quoteId: string;

    @Input() loading: boolean;

    @Input() set removeAddCategory(value: { add: boolean; category: IDocumentTypeDto }) {
        if (value && !value.add) {
            this.removeCategory(value.category);
        } else if (value && value.add) {
            this.addCategory(value.category);
        }
        this._cdr.detectChanges();
    }

    @Output()
    allDocumentsUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    documentUploaded: EventEmitter<IContractDocumentDto> = new EventEmitter<IContractDocumentDto>();

    user$: Observable<IUserInfoDto>;

    public categories: Array<{ value: IDocumentTypeDto; text: string }> = [];

    public clearUploadedFiles: boolean;

    private _user: IUserInfoDto;

    private contractNumber: string;

    public countInvoices$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public get contract_number(): string {
        return this.contractNumber;
    }

    constructor(
        private readonly _contractsDocumentsService: ContractsDocumentService,
        private readonly _translate: TranslationFacade,
        private readonly _notify: ToastService,
        private readonly _cdr: ChangeDetectorRef,
        private readonly _userService: UserService,
    ) { }

    ngOnInit(): void {
        this.user$ = this._userService.userInfo;
        this._userService.userInfo.pipe(untilDestroyed(this))
            .subscribe(user => this._user = user);
    }

    uploadFiles(files: Array<CustomFile>) {
        this.loading = true;
        this.clearUploadedFiles = false;
        Array.from(files).forEach((_file, index) => {
            const file = _file;
            this._contractsDocumentsService.uploadDocument({
                contractNumber: this.contractNumber,
                documentType: file.category as any,
                body: {
                    file,
                },
            })
                .pipe(untilDestroyed(this))
                .subscribe((res) => {
                    this.setUploadedFiles(file, res, files.length, index);
                },
                (_error) => {
                    this.setUploadedFiles(file, null, files.length, index);
                    this._notify.showError('Fehler beim hochladen!');
                });
        });
    }

    private setUploadedFiles(file: CustomFile, _id: string, _length: number, i: number) {
        if (isUserInfoLogins(this._user)) {
            const translatedCategory = this._translate.instant(`contract_management.documents.${file.category}`);
            const _file: IContractDocumentDto = {
                id: _id,
                name: `${this.contractNumber}_${translatedCategory}_${file.name}`,
                type: file.category,
                createdBy: `${this._user.loginsInfo.givenName} ${this._user.loginsInfo.familyName}`,
                creationDate: (new Date()).toDateString(),
                archiveInfo: null,
            };

            if (_id) {
                this.documentUploaded.emit(_file);
            }

            if (file.category === IDocumentTypeDto.Invoice) {
                this.removeCategory(IDocumentTypeDto.Invoice);
            }

            if (_length === i + 1) {
                this.clearUploadedFiles = true;
                this.loading = false;
                this._notify.showSuccess('Dokument(s) hochgeladen!');
                this.allDocumentsUploaded.emit(true);
                this._cdr.detectChanges();
            }
        }
    }

    private addCategory(category: IDocumentTypeDto): void {
        const translatedCategory = this._translate.instant(`contract_management.documents.${category}`);
        this.categories.push({ text: translatedCategory, value: category });
    }

    private removeCategory(category: IDocumentTypeDto): void {
        this.categories = this.categories.filter(_category => _category.value !== category);
    }

    filesToUpload(files: Array<CustomFile>): void {
        const countInvoices = files.filter(file => file?.category === IDocumentTypeDto.Invoice).length;
        this.countInvoices$.next(countInvoices);
    }

}
