// #region Imports

import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DEFAULT_LANGUAGE, MissingTranslationHandler, TranslateCompiler, TranslateDefaultParser, TranslateLoader, TranslateModule, TranslateParser, TranslateService, TranslateStore, USE_DEFAULT_LANG, USE_EXTEND, USE_STORE } from '@ngx-translate/core';
import { TranslationFacade } from '../Services/TranslationFacade';
import { DefaultMissingTranslationhandler } from '../Translations/DefaultMissingTranslationhandler';
import { DefaultTranslateHttpLoader } from '../Translations/DefaultTranslateHttpLoader';
import { DefaultTranslationCompiler } from '../Translations/DefaultTranslationCompiler';

// #endregion

export function translateLoaderFactory(http: HttpClient): TranslateLoader {
    return new DefaultTranslateHttpLoader(http, '/assets/i18n/', `.json?v=${new Date().getTime()}`);
}

/**
 * The `TranslationModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        TranslateModule,
    ],
    declarations: [
        // @todo: Fix the broken translation pipe and has performance issue
        // TranslatePipe
    ],
    providers: [
        TranslationFacade,
        { provide: TranslateLoader, useFactory: translateLoaderFactory, deps: [HttpClient] },
        { provide: TranslateCompiler, useClass: DefaultTranslationCompiler },
        { provide: TranslateParser, useClass: TranslateDefaultParser },
        { provide: MissingTranslationHandler, useClass: DefaultMissingTranslationhandler },
        { provide: TranslateStore, useClass: TranslateStore },
        { provide: TranslateService, useClass: TranslateService },
        { provide: USE_STORE, useValue: true },
        { provide: USE_DEFAULT_LANG, useValue: true },
        { provide: USE_EXTEND, useValue: false },
        { provide: DEFAULT_LANGUAGE, useValue: 'de' },
    ],
    exports: [
        TranslateModule,
    ],
})
export class TranslationModule {

}
