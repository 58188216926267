import { ContractFunctionService, ISignatureResponseDto } from '@abcfinlab/api/contract';
import { TranslationModule } from '@abcfinlab/core';
import { BusyBoxModule, DialogHeaderModule, TextBlockModule } from '@abcfinlab/ui';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ICheckSignaturesDialogData {
    contractNumber: string;
}
@Component({
    selector: 'l7-check-signatures-dialog',
    templateUrl: './check-signatures-dialog.component.html',
    styleUrls: ['./check-signatures-dialog.component.scss'],
})
@UntilDestroy()
export class CheckSignaturesDialogComponent implements OnInit {

    // #region Fields
    private readonly _contractFunctionService: ContractFunctionService;
    private readonly _dialogRef: MatDialogRef<CheckSignaturesDialogComponent>;
    private readonly _data: ICheckSignaturesDialogData;
    private readonly _signaturesData: BehaviorSubject<ISignatureResponseDto> = new BehaviorSubject<ISignatureResponseDto>(null);

    /**
     * Returns the `signaturesData` property.
     *
     * @public
     * @readonly
     */
    public get signaturesData(): Observable<ISignatureResponseDto | null> {
        return this._signaturesData.asObservable();
    }
    // #endregion

    public constructor(@Inject(MAT_DIALOG_DATA) data: ICheckSignaturesDialogData, dialogRef: MatDialogRef<CheckSignaturesDialogComponent>,
        contractFunctionService: ContractFunctionService) {
        this._data = data;
        this._contractFunctionService = contractFunctionService;
        this._dialogRef = dialogRef;
    }

    public ngOnInit(): void {
        this._contractFunctionService.getSignatureResponse({ contractNumber: this._data.contractNumber })
            .pipe(untilDestroyed(this))
            .subscribe((signatures) => {
                this._signaturesData.next(signatures);
            });
    }

    public onClose(): void {
        this._dialogRef.close();
    }

}

@NgModule({
    imports: [
        CommonModule,
        TranslationModule,
        MatCardModule,
        MatIconModule,
        DialogHeaderModule,
        MatDialogModule,
        FlexLayoutModule,
        CdkScrollableModule,
        BusyBoxModule,
        TextBlockModule,
    ],
    declarations: [
        CheckSignaturesDialogComponent,
    ],
    exports: [
        CheckSignaturesDialogComponent,
    ],
    // providers: [],
})
export class CheckSignaturesDialogModule { }
