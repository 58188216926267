import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '@abcfinlab/auth';
import { IUserInfoDto } from '@abcfinlab/api/login';

@Injectable({
    providedIn: 'root',
})
export class UserInfoResolver implements Resolve<IUserInfoDto> {

    constructor(
        private readonly _userService: UserService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IUserInfoDto> {
        return this._userService.getUserInfo().pipe(
            catchError((err) => {
                return throwError(err);
            }),
        );
    }

}
