import { HttpErrorResponse } from '@angular/common/http';
import { ServerErrorDTO } from '../ServerErrorDTO.interface';
import { environment } from '../../../environments/environment';

export class ErrorParser {

    constructor() {
    }

    static getInternalErrorCode(error: HttpErrorResponse): string {
        const errorObject: ServerErrorDTO = error.error;
        if (errorObject.error) {
            return errorObject.error;
        } else {
            return null;
        }
    }

    /**
   * Hack until BE provides parameters.
   * @param errorMessage    The error message from the backend.
   */
    static getNumericErrorParams(errorMessage: string): Array<string> {
        if (!errorMessage) {
            return [];
        }
        const regExResult = errorMessage.match(/([^\d]|^)\d{1,20}.\d{1,20}([^\d]|$)|([^\d]|^)\d{1,20}([^\d]|$)/g);
        const translationParams = [];
        try {
            for (let i = 0; i < regExResult.length; i++) {
                const param = {};
                param[`param${i}`] = regExResult[i].trim();
                translationParams.push(param);
            }
        } catch (e) {
            return [];
        }
        return translationParams;
    }

    static addMissingText(errorDescription: string): string {
        if (!environment.production) {
            return `${errorDescription} (TRANSLATION OR ERROR CODE MISSING/TOO GENERIC)`;
        }
        return errorDescription;
    }

    /**
   * Check if a error translation key exists.
   * The translation itself must be different to the key, therefor the function must returned by !==.
   * https://github.com/ngx-translate/core/issues/1128
   * @param translationField      The field to look up for
   */
    static translationExists(translationField: string, translation: string): boolean {
        return translation.toString() !== `error.${translationField.toString()}`;
    }

}
