// #region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import { TextBlockModule } from '../TextBlock/TextBlockModule';
import { SummaryComponent } from './SummaryComponent';
import { SummaryItemComponent } from './SummaryItemComponent';
import { SummaryItemIconDirective } from './SummaryItemIconDirective';

// #endregion

/**
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        FlexModule,
        TextBlockModule,
        MatIconModule,
    ],
    declarations: [
        SummaryComponent,
        SummaryItemComponent,
        SummaryItemIconDirective,
    ],
    exports: [
        SummaryComponent,
        SummaryItemComponent,
        SummaryItemIconDirective,
    ],
})
export class SummaryModule {
}
