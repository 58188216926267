// #region Imports

import { Directive, Input, TemplateRef } from '@angular/core';

// #endregion

/**
 * The `SummaryItemIconDirective` directive.
 *
 * @public
 */
@Directive({
    selector: 'ng-template[l7SummaryItemIcon]',
    exportAs: 'l7SummaryItemIcon',
})
export class SummaryItemIconDirective {

    // #region Fields

    private readonly _template: TemplateRef<unknown>;
    private _position: 'before' | 'after';

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `SelectAllDirective` class.
     *
     * @public
     */
    public constructor(templateRef: TemplateRef<unknown>) {
        this._template = templateRef;
        this._position = 'before';
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `position` property.
     *
     * @public
     */
    @Input('l7SummaryItemIcon')
    public get position(): 'before' | 'after' {
        return this._position;
    }

    public set position(value: 'before' | 'after') {
        this._position = value;
    }

    /**
     * Returns the `template` property.
     *
     * @public
     * @readonly
     */
    public get template(): TemplateRef<unknown> {
        return this._template;
    }

    // #endregion

}
