// #region Imports

import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SettlementOverviewViewPresenter } from './SettlementOverviewViewPresenter';

// #endregion

/**
 * The `SettlementOverviewView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-settlement-overview-view',
    templateUrl: './SettlementOverviewView.html',
    styleUrls: ['./SettlementOverviewView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('detailExpand', [
            state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('*', style({ height: '*', visibility: 'visible' })),
            transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    providers: [
        SettlementOverviewViewPresenter,
    ],
})
export class SettlementOverviewView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: SettlementOverviewViewPresenter;
    public ignoreReadOnlyBanner: boolean = false;
    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `SettlementOverviewView` class.
     *
     * @public
     */
    public constructor(presenter: SettlementOverviewViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `SettlementOverviewView`
     *
     * @public
     * @readonly
     */
    public get presenter(): SettlementOverviewViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }
    // #endregion

}
