import { Directive, ElementRef, OnDestroy } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
    selector: '[l7MaskDate]',
})
export class MaskDateDirective implements OnDestroy {

    mask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]; // dd.mm.yyyy
    maskedInputController;

    constructor(private readonly element: ElementRef) {
        this.maskedInputController = textMask.maskInput({
            inputElement: this.element.nativeElement,
            mask: this.mask,
        });
    }

    ngOnDestroy() {
        this.maskedInputController.destroy();
    }

}
