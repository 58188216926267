// #region Imports

import {
    ContractFunctionService,
    ContractsDocumentService,
    IInsuranceAndHandlingDto,
    IInsuranceTypeDto,
    IProcessingStatusDto,
    ISaleAndLeaseBackCodeDto,
    ISalesChannelDto,
    ISearchContractResultDto,
} from '@abcfinlab/api/contract';
import { IObjectGroupDto } from '@abcfinlab/api/global';
import { IUserGroupLoginsDto, IUserGroupRetailerDto, UserService } from '@abcfinlab/auth';
import { arrayEquals, EventHub, Globals, TranslationFacade } from '@abcfinlab/core';
import { convertNavStatusToNumber, UiStatesPreservationService } from '@abcfinlab/presentation';
import { BusyBoxService, FilterCondition, FilterConditionComparer } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ISettlementSendToAccount } from 'libs/backoffice/src/Models/ISettlementSendToAccount';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { calculateInsuranceValueRange } from '../../../../apps/shell/src/app/helper/calculation.helper';
import { ISettlementContractInformationInfo } from '../Models/ISettlementContractInformationInfo';
import { ISettlementOverviewSearchCondition } from '../Models/ISettlementOverviewSearchCondition';
import { KnownEvents } from '../Models/KnownEvents';
import { KnownSettings } from '../Models/KnownSettings';

// #endregion

/**
 * @private
 */
const EMPTY_SEARCH_CONDITION: FilterCondition<ISettlementOverviewSearchCondition> = {
    term: '',
    status: [],
};

/**
 * @private
 */
const isEmptySearchCondition: FilterConditionComparer<ISettlementOverviewSearchCondition> = other => EMPTY_SEARCH_CONDITION.term.toLowerCase() === other.term.toLowerCase()
    && arrayEquals(EMPTY_SEARCH_CONDITION.status, other.status);

/**
 * The presenter of the {@link SettlementOverviewView} view.
 *
 * @internal
 */
@UntilDestroy()
@Injectable()
export class SettlementOverviewViewPresenter {

    // #region Fields

    private readonly _contractsService: ContractFunctionService;
    private readonly _uiStatesPreservationService: UiStatesPreservationService;
    private readonly _contractsDocumentService: ContractsDocumentService;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _eventHub: EventHub;
    private readonly _columns: Array<string>;
    private readonly _dataSource: MatTableDataSource<ISearchContractResultDto>;
    private readonly _pageSizeSubject: BehaviorSubject<number>;
    private readonly _isSlbQuote: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _pageSizesSubject: BehaviorSubject<Array<number>>;
    private readonly _dataSourceTotalSubject: BehaviorSubject<number>;
    private readonly _conditionSubject: BehaviorSubject<FilterCondition<ISettlementOverviewSearchCondition>>;
    private readonly _defaultConditionSubject: BehaviorSubject<FilterCondition<ISettlementOverviewSearchCondition>>;
    private readonly _conditionComparerSubject: BehaviorSubject<FilterConditionComparer<ISettlementOverviewSearchCondition>>;
    private readonly _isDefaultSearchConditionSubject: BehaviorSubject<boolean>;
    private readonly _statusSubject: BehaviorSubject<Array<IProcessingStatusDto>>;
    private readonly _contractInformation: BehaviorSubject<ISettlementContractInformationInfo> = new BehaviorSubject<ISettlementContractInformationInfo>(null);
    private readonly _navStatus: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private readonly _contractStatus: BehaviorSubject<IProcessingStatusDto> = new BehaviorSubject<IProcessingStatusDto>(null);
    private readonly _salesChanel: BehaviorSubject<ISalesChannelDto> = new BehaviorSubject<ISalesChannelDto>(null);
    private readonly _totalLeasingValue: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private readonly _insuranceMaxAndMinValues: BehaviorSubject<{ max: number; min: number }> = new BehaviorSubject<{ max: number; min: number }>({ max: 0, min: 0 });
    private readonly _userService: UserService;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `SettlementOverviewViewPresenter` class.
     *
     * @public
     */
    public constructor(contractsService: ContractFunctionService, contractsDocumentService: ContractsDocumentService,
        busyBoxService: BusyBoxService, translationFacade: TranslationFacade, eventHub: EventHub,
        uiStatesPreservation: UiStatesPreservationService, userService: UserService) {
        this._contractsService = contractsService;
        this._uiStatesPreservationService = uiStatesPreservation;
        this._contractsDocumentService = contractsDocumentService;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._eventHub = eventHub;
        this._columns = [
            'sales.full_name',
            'nav_contract_number',
            'lessee.name',
            'object.name',
            'object.value',
            'billable_since',
            'status',
        ];
        this._dataSource = new MatTableDataSource();
        this._pageSizeSubject = new BehaviorSubject(Globals.Page.DEFAULT_PAGE_SIZE);
        this._pageSizesSubject = new BehaviorSubject(Globals.Page.DEFAULT_PAGE_SIZES);
        this._dataSourceTotalSubject = new BehaviorSubject(0);
        this._conditionSubject = new BehaviorSubject(EMPTY_SEARCH_CONDITION);
        this._defaultConditionSubject = new BehaviorSubject(EMPTY_SEARCH_CONDITION);
        this._conditionComparerSubject = new BehaviorSubject(isEmptySearchCondition);
        this._isDefaultSearchConditionSubject = new BehaviorSubject(true);
        this._statusSubject = new BehaviorSubject<Array<IProcessingStatusDto>>([
            IProcessingStatusDto.SentByMail,
            IProcessingStatusDto.Billable,
            IProcessingStatusDto.Manual,
            IProcessingStatusDto.Signed,
            IProcessingStatusDto.ToBeDiscussed,
            IProcessingStatusDto.Transferred,
            IProcessingStatusDto.ManuallyDiscarded,
        ]);
        this._userService = userService;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `columns` property.
     *
     * @public
     * @readonly
     */
    public get columns(): Array<string> {
        return this._columns;
    }

    /**
     * Returns the `dataSource` property.
     *
     * @public
     * @readonly
     */
    public get dataSource(): MatTableDataSource<ISearchContractResultDto> {
        return this._dataSource;
    }

    /**
     * Returns the `dataSourceTotal` property.
     *
     * @public
     * @readonly
     */
    public get dataSourceTotal(): Observable<number> {
        return this._dataSourceTotalSubject.asObservable();
    }

    /**
     * Returns the `pageSize` property.
     *
     * @public
     * @readonly
     */
    public get pageSize(): Observable<number> {
        return this._pageSizeSubject.asObservable();
    }

    /**
     * Returns the `pageSizes` property.
     *
     * @public
     * @readonly
     */
    public get pageSizes(): Observable<Array<number>> {
        return this._pageSizesSubject.asObservable();
    }

    /**
     * Returns the `defaultCondition` property.
     *
     * @public
     * @readonly
     */
    public get defaultCondition(): Observable<FilterCondition<ISettlementOverviewSearchCondition>> {
        return this._defaultConditionSubject.asObservable();
    }

    /**
     * Returns the `condition` property.
     *
     * @public
     * @readonly
     */
    public get condition(): Observable<FilterCondition<ISettlementOverviewSearchCondition>> {
        return this._conditionSubject.asObservable();
    }

    /**
     * Returns the `conditionComparer` property.
     *
     * @public
     * @readonly
     */
    public get conditionComparer(): Observable<FilterConditionComparer<ISettlementOverviewSearchCondition>> {
        return this._conditionComparerSubject.asObservable();
    }

    /**
     * Returns the `isDefaultSearchCondition` property.
     *
     * @public
     * @readonly
     */
    public get isDefaultSearchCondition(): Observable<boolean> {
        return this._isDefaultSearchConditionSubject.asObservable();
    }

    /**
     * Returns the `status` property.
     *
     * @public
     * @readonly
     */
    public get status(): Observable<Array<IProcessingStatusDto>> {
        return this._statusSubject.asObservable();
    }

    /**
     * Returns the `navision status` property.
     *
     * @public
     * @readonly
     */
    public get navStatus(): Observable<number> {
        return this._navStatus.asObservable();
    }

    /**
     * Returns the `contract status` property.
     *
     * @public
     * @readonly
     */
    public get contractStatus(): Observable<IProcessingStatusDto> {
        return this._contractStatus.asObservable();
    }

    /**
     * Returns the `contractInformation` property.
     *
     * @public
     * @readonly
     */
    public get contractInformation(): Observable<ISettlementContractInformationInfo> {
        return this._contractInformation.asObservable();
    }

    /**
     * Returns the `salesChanel` property.
     *
     * @public
     * @readonly
     */
    public get salesChanel(): Observable<ISalesChannelDto> {
        return this._salesChanel.asObservable();
    }

    public get isSlbQuote(): Observable<boolean> {
        return this._isSlbQuote.asObservable();
    }

    public get totalLeasingValue(): Observable<number> {
        return this._totalLeasingValue.asObservable();
    }

    public get insuranceMaxAndMinValues(): Observable<{ max: number; min: number }> {
        return this._insuranceMaxAndMinValues.asObservable();
    }
    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this._eventHub.getEvent<ISettlementContractInformationInfo>(KnownEvents.SETTLEMENT_DETAIL_LOADED)
            .pipe(untilDestroyed(this))
            .subscribe((_x) => {
                if (_x) {
                    this._isSlbQuote.next(_x.quote?.slb);
                    this._salesChanel.next(_x.quote?.sales_channel);
                    const totalLeasingValue = _x.hasPurchasePriceChanged ? _x.changedPurchasePriceValues.totalLeasingValueNet : _x.newGetQuote.inhouseQuoteCalculation.quoteCalculation.totalLeasingValue;
                    this._totalLeasingValue.next(totalLeasingValue);
                    const navStatus = convertNavStatusToNumber(_x.contract?.status);
                    this._navStatus.next(navStatus);
                    this.calculateInsuranceMaxAndMinValues(_x.objGroup, _x.insuranceAndHandlingFee);
                    this._contractStatus.next(_x.row.status);
                    this._contractInformation.next(_x);
                }
            });

        this._eventHub.getEvent<ISettlementSendToAccount>(KnownEvents.SETTLEMENT_SEND_TO_ACCOUNTANT_SUCCEED)
            .pipe(untilDestroyed(this))
            .subscribe((_x) => {
                const contractInfo = this._contractInformation.getValue();
                if (_x && contractInfo.row.nav_contract_number === _x.contractNumber) {
                    contractInfo.row.status = _x.status;
                    this._contractStatus.next(_x.status);
                }
            });
        this.initializeCondition();
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        //
    }

    /**
     * @internal
     */
    public onTermChanged(value: string): void {
        const condition = {
            ...this._conditionSubject.value,
            term: value.trim().toLowerCase(),
        };

        this._conditionSubject.next(condition);
        this._isDefaultSearchConditionSubject.next(isEmptySearchCondition(this._conditionSubject.value));
        this._uiStatesPreservationService.set(KnownSettings.SettlementFilterCondition, condition);

        this.initializeContracts(this._pageSizeSubject.value, 0);
    }

    /**
     * @internal
     */
    public onConditionChanged(status: Array<IProcessingStatusDto>): void {
        const condition = {
            ...this._conditionSubject.value,
            status: status,
        };

        this._conditionSubject.next(condition);
        this._isDefaultSearchConditionSubject.next(isEmptySearchCondition(this._conditionSubject.value));
        this._uiStatesPreservationService.set(KnownSettings.SettlementFilterCondition, condition);
        this.initializeContracts(this._pageSizeSubject.value, 0);
    }

    /**
     * @internal
     */
    public onResetConditions(): void {
        this._conditionSubject.next(EMPTY_SEARCH_CONDITION);
        this._isDefaultSearchConditionSubject.next(true);
        this._uiStatesPreservationService.remove(KnownSettings.SettlementFilterCondition);
        this.initializeContracts(this._pageSizeSubject.value, 0);
    }

    /**
     * @internal
     */
    public onPageChanged(page: PageEvent): void {
        this._pageSizeSubject.next(page.pageSize);
        this.initializeContracts(page.pageSize, page.pageIndex);
    }

    /**
     * @internal
     */
    public onRowSelected(row: ISearchContractResultDto): void {
        this._eventHub.publish(KnownEvents.SETTLEMENT_CHANGED, row);
        this._contractsDocumentService.getContractsDocuments({ contractNumber: row.nav_contract_number })
            .pipe(untilDestroyed(this))
            .subscribe((_documents) => {
                this._eventHub.publish(KnownEvents.SETTLEMENT_DOCUMENTS, _documents);
            });
    }

    /**
     * @private
     */
    private initializeCondition(): void {
        this._uiStatesPreservationService.get<ISettlementOverviewSearchCondition>(KnownSettings.SettlementFilterCondition).subscribe((x) => {
            if (x) {
                this._conditionSubject.next(x);
                this._isDefaultSearchConditionSubject.next(false);
            }
            this.initializeContracts(this._pageSizeSubject.value, 0);
        });
    }

    /**
     * @private
     */
    private initializeContracts(size: number, index: number): void {
        this._busyBoxService.show('', this._translationFacade.translate('global.busy'), this._contractsService.search({
            page: index,
            pageSize: size,
            searchString: this._conditionSubject.value.term,
            status: this._conditionSubject.value.status,
        })).subscribe((x) => {
            this._dataSource.data = x.content;
            this._dataSourceTotalSubject.next(x.totalElements);
        });
    }

    private calculateInsuranceMaxAndMinValues(objectGroup: IObjectGroupDto, insuranceAndHandlingFee: IInsuranceAndHandlingDto): void {
        const insuranceMaxAndMinValues = {
            max: 0,
            min: 0,
        };
        if (insuranceAndHandlingFee.insurance_type === IInsuranceTypeDto.Standard) {
            const getInsuranceMaxAndMinValues = calculateInsuranceValueRange(this._totalLeasingValue.getValue(), objectGroup.insurance_value);
            insuranceMaxAndMinValues.max = getInsuranceMaxAndMinValues.maxValue;
            insuranceMaxAndMinValues.min = getInsuranceMaxAndMinValues.minValue;
        } else if (insuranceAndHandlingFee.insurance_type === IInsuranceTypeDto.Pro100) {
            insuranceMaxAndMinValues.min = insuranceAndHandlingFee.default_insurance_value100_pro;
        }
        this._insuranceMaxAndMinValues.next(insuranceMaxAndMinValues);
    }

    public get isReadonlyGroup(): Observable<boolean> {
        return this._userService.userGroups.pipe(
            map(x => x.some(y => y === IUserGroupRetailerDto.L7BackofficeReadonlyRetailer || y === IUserGroupLoginsDto.L7BackofficeReadonly)),
        );
        // return true;
    }

    public get isUnechtLnNeu(): Observable<boolean> {
        return this._contractInformation.pipe(
            map(info => info?.slb?.code === ISaleAndLeaseBackCodeDto.UnechtlnV2),
        );
    }
    // #endregion

}
