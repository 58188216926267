// #region Imports

import { NgModule, Pipe, PipeTransform } from '@angular/core';

// #endregion

@Pipe({
    name: 'keys',
})
export class KeysPipe implements PipeTransform {

    // #region Methods

    public transform(value: object): Array<string> {
        return Object.keys(value);
    }

    // #endregion

}

@NgModule({
    declarations: [KeysPipe],
    exports: [KeysPipe],
})
export class KeysPipeModule {
}
