import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, OnInit } from '@angular/core';

@Directive({
    selector: '[l7Absolute]',
})
export class AbsoluteDirective implements OnChanges, OnInit {

    // #region Fields

    private readonly _el: ElementRef;
    private readonly _renderer: Renderer2;

    // #endregion

    public constructor(el: ElementRef, renderer: Renderer2) {
        this._el = el;
        this._renderer = renderer;
    }

    // #region Properties

    /**
     * Sets the inset of the element.
     * The format is `top;right;bottom;left`.
     * @public
     */
    @Input('l7Absolute')
    public inset: string = '';

    // #endregion

    // #region Methods

    public ngOnInit(): void {
        this._renderer.setStyle(this._el.nativeElement, 'position', 'absolute');
    }

    /**
     * @public
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.inset) {
            const [top, right, bottom, left] = this.inset.split(';').map(s => s.trim());
            this._renderer.setStyle(this._el.nativeElement, 'top', top);
            this._renderer.setStyle(this._el.nativeElement, 'right', right);
            this._renderer.setStyle(this._el.nativeElement, 'bottom', bottom);
            this._renderer.setStyle(this._el.nativeElement, 'left', left);
        }
    }

    // #endregion

}
