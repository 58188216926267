// #region Imports

import {
    ChangeDetectionStrategy,
    Component, EventEmitter, Input, OnChanges,
    OnDestroy,
    OnInit,
    Output, SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    RetailerSignContractViewPresenter,
} from '../../../../presentation/src/Components/retailer-sign-contract/RetailerSignContractViewPresenter';
import { SignaturePadComponent } from '@abcfinlab/presentation';
import { SignaturePadOptions } from '../../../../legacy/gwg/src/lib/signature/signature/signature.component';
import * as blobUtil from 'blob-util';
import {
    IRetailerQuoteResultDto,
    IRetailerSignerDto,
    MoneyLaunderingService,
    PdfDraftsDownloadService,
    PdfRemoteService,
    PdfService,
} from '@abcfinlab/api/global';
import { IContactDto } from '@abcfinlab/api/contact';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
    IBankAccountFormDto,
} from '../../../../quote/src/Views/Dialogs/retailer-identification/RetailerIdentificationViewPresenter';

// #endregion

/**
 * The `RetailerSignContractView` view.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-retailer-sign-contract-view',
    templateUrl: './RetailerSignContractView.html',
    styleUrls: ['./RetailerSignContractView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        RetailerSignContractViewPresenter,
    ],
})
export class RetailerSignContractView implements OnInit, OnDestroy, OnChanges {

    // #region Fields

    private readonly _presenter: RetailerSignContractViewPresenter;

    private readonly _documentService: PdfDraftsDownloadService;

    private readonly _moneyLaunderingService: MoneyLaunderingService;

    private readonly _pdfService: PdfService;

    private readonly _pdfRemoteService: PdfRemoteService;

    private readonly _route: ActivatedRoute;

    private _quoteId: string;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `RetailerSignContractView` class.
     *
     * @public
     */
    public constructor(presenter: RetailerSignContractViewPresenter, moneyLaunderingService: MoneyLaunderingService,
        documentService: PdfDraftsDownloadService, pdfService: PdfService, pdfRemoteService: PdfRemoteService, route: ActivatedRoute) {
        this._presenter = presenter;
        this._moneyLaunderingService = moneyLaunderingService;
        this._documentService = documentService;
        this._pdfService = pdfService;
        this._pdfRemoteService = pdfRemoteService;
        this._route = route;
    }

    // #endregion

    // #region Properties
    @Input() quoteDetails: IRetailerQuoteResultDto;

    @Input() signer: IRetailerSignerDto = null;

    @Input() bankAccount: IBankAccountFormDto = null;

    @Input() id: string;

    @Input() lessee: IContactDto;

    @Input() type: 'LOCAL' | 'REMOTE' | 'REMOTE_WITHOUT_IDENTIFICATION' | 'LOCAL_WITHOUT_IDENTIFICATION';

    public signaturePadOptions: SignaturePadOptions = {
        minWidth: 3,
        canvasWidth: 1000,
        canvasHeight: 256,
    };

    @ViewChild('signaturePad') signaturePad: SignaturePadComponent;

    @Output() contractClosed: EventEmitter<'LOCAL' | 'REMOTE' | 'ERROR' | 'REMOTE_WITHOUT_IDENTIFICATION' | 'LOCAL_WITHOUT_IDENTIFICATION'> = new EventEmitter<'LOCAL' | 'REMOTE' | 'ERROR' | 'REMOTE_WITHOUT_IDENTIFICATION' | 'LOCAL_WITHOUT_IDENTIFICATION'>();

    /**
     * Returns the presenter of the `RetailerSignContractViewPresenter`
     *
     * @public
     * @readonly
     */
    public get presenter(): RetailerSignContractViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    public ngOnInit(): void {
        this._presenter.initialize(this.quoteDetails, this.lessee, this.id, this.type);
        this.presenter.signingCompleted.pipe(untilDestroyed(this))
            .subscribe((res) => {
                if (res) {
                    this.contractClosed.emit(this.type);
                }
            });
        this.presenter.signingContractHasError.pipe(untilDestroyed(this))
            .subscribe((res) => {
                if (res) {
                    this.contractClosed.emit('ERROR');
                }
            });
        this.presenter.id.pipe(untilDestroyed(this))
            .subscribe(id => this._quoteId = id);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.signer && !changes.signer.firstChange) {
            this.presenter.updateSigner(changes.signer.currentValue);
        }

        const hasIbanOrBankAccountNameChanged = changes.bankAccount.currentValue.iban !== changes.bankAccount.previousValue.iban
            || changes.bankAccount.currentValue.bankAccountName !== changes.bankAccount.previousValue.bankAccountName;
        if (changes.bankAccount && !changes.bankAccount.firstChange && hasIbanOrBankAccountNameChanged) {
            this.presenter.updateBankAccount(changes.bankAccount.currentValue);
        }
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    public clearSignaturePad(): void {
        this.signaturePad.clear();
        this.presenter.signatureFile.next(null);
    }

    public drawComplete(): void {
        let canvas = (this.signaturePad as any).elementRef.nativeElement.querySelector('canvas');
        canvas = this.trim(canvas);

        const signatureBlob = blobUtil.dataURLToBlob(canvas.toDataURL('image/jpg'));
        this.presenter.signatureFile.next(signatureBlob);
    }

    public drawStart(): void {
        // this.canvasDirty.next(true);
    }

    public openGPDRFile(evt: Event): void {
        evt.preventDefault();
        this._moneyLaunderingService.getPrivacyPolicyFile().pipe(untilDestroyed(this)).subscribe(pdfData => this._handlePDFDownload(pdfData));
        evt.stopPropagation();
    }

    public openContractDraft(ev: Event): void {
        ev.preventDefault();
        let $pdfDownloadRequest = new Observable();
        if (this.type === 'REMOTE') {
            const signingLinkId = this._route.snapshot.queryParamMap.get('action');
            $pdfDownloadRequest = this._pdfRemoteService.downloadUnsignedContract({ signingLinkId });
        } else {
            $pdfDownloadRequest = this._documentService.downloadDraftPdf({ id: this._quoteId });
        }

        $pdfDownloadRequest.pipe(untilDestroyed(this)).subscribe(pdfData => this._handlePDFDownload(pdfData as Blob));
        ev.stopPropagation();
    }

    public onOpenExclusionOfWarranty(ev: Event): void {
        ev.preventDefault();
        let $pdfDownloadRequest = new Observable();
        if (this.type === 'REMOTE') {
            const signingLinkId = this._route.snapshot.queryParamMap.get('action');
            $pdfDownloadRequest = this._pdfRemoteService.downloadUnsignedEowDraft({ signingLinkId });
        } else {
            $pdfDownloadRequest = this._pdfService.downloadEowDraft({ leasingQuoteId: this._quoteId });
        }

        $pdfDownloadRequest.pipe(untilDestroyed(this)).subscribe(pdfData => this._handlePDFDownload(pdfData as Blob));
        ev.stopPropagation();
    }

    private _handlePDFDownload(pdfBlob: Blob): void {
        const url = URL.createObjectURL(pdfBlob);
        window.open(url, '_blank');
    }

    private trim(c: HTMLCanvasElement): HTMLCanvasElement {
        const ctx = c.getContext('2d');
        const copy = document.createElement('canvas').getContext('2d');
        const pixels = ctx.getImageData(0, 0, c.width, c.height);
        const l = pixels.data.length;
        let i;
        const bound = {
            top: null,
            left: null,
            right: null,
            bottom: null,
        };
        let x;
        let y;

        for (i = 0; i < l; i += 4) {
            if (pixels.data[i + 3] !== 0) {
                x = (i / 4) % c.width;
                y = ~~((i / 4) / c.width);

                if (bound.top === null) {
                    bound.top = y;
                }

                if (bound.left === null) {
                    bound.left = x;
                } else if (x < bound.left) {
                    bound.left = x;
                }

                if (bound.right === null) {
                    bound.right = x;
                } else if (bound.right < x) {
                    bound.right = x;
                }

                if (bound.bottom === null) {
                    bound.bottom = y;
                } else if (bound.bottom < y) {
                    bound.bottom = y;
                }
            }
        }

        const trimHeight = bound.bottom - bound.top;
        const trimWidth = bound.right - bound.left;
        const trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

        copy.canvas.width = trimWidth;
        copy.canvas.height = trimHeight;
        copy.putImageData(trimmed, 0, 0);

        return copy.canvas;
    }

    // #endregion

}
