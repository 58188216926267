// #region Imports

import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, EventEmitter, HostBinding, Input, OnDestroy, Output, QueryList, ViewEncapsulation } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';

// #endregion

/**
 * @public
 */
@Component({
    selector: 'l7-checkbox-group',
    templateUrl: './CheckboxGroupComponent.html',
    exportAs: 'l7checkboxGroupComponent',
    host: {
        '[attr.disabled]': 'disabled ? true : null',
        '[attr.aria-disabled]': 'disabled ? true : null',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CheckboxGroupComponent implements AfterContentInit, OnDestroy {

    // #region Fields

    @ContentChildren(MatCheckbox, { descendants: true })
    private readonly _items?: QueryList<MatCheckbox>;

    private readonly _changed: EventEmitter<any>;

    private _itemsChangedSubscription: Subscription;
    private readonly _itemsChangedSubscriptions: Array<Subscription>;
    private _lockitemSubscriptions: boolean;
    private _disabled: boolean;
    private _required: boolean;
    private _name: string;
    private _allChecked: boolean | null;
    private _isInitialized: boolean;
    private _values: Array<unknown>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `CheckboxGroupComponent` class.
     *
     * @public
     */
    public constructor() {
        this._name = '';
        this._allChecked = false;
        this._disabled = false;
        this._required = false;
        this._lockitemSubscriptions = false;
        this._isInitialized = false;
        this._values = [];
        this._changed = new EventEmitter();
        this._itemsChangedSubscription = Subscription.EMPTY;
        this._itemsChangedSubscriptions = [];
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets a value indicating whether the {@link CheckboxGroupComponent} is disabled in the user interface(UI).
     * Sets the dom class property and the aria attribute as true value, otherwise nothing.
     *
     * @public
     * @type boolean
     * @default false
     */
    @Input()
    @HostBinding('class.disabled')
    public get disabled(): boolean {
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = value;
    }

    /**
     * Gets or sets the `name` attribute of the `CheckboxGroupComponent`.
     * The `name` is inherited to all `CheckBoxComponent` children.
     *
     * @public
     * @type string
     * @default ''
     */
    @Input()
    public get name(): string {
        return this._name;
    }

    public set name(value: string) {
        this._name = value;
    }

    /**
     * Gets or sets the required value of the `CheckboxGroupComponent` children.
     * If not set, `required` will have value `false`.
     * The `labelPosition` is inherited to all `CheckboxGroupComponent` children.
     *
     * @public
     * @type boolean
     * @default false
     */
    @Input()
    @HostBinding('class.required')
    public get required(): boolean {
        return this._required;
    }

    public set required(value: boolean) {
        this._required = value;
    }

    /**
     * Gets or sets the `values` property of the selected Checkbox.
     *
     * @public
     * @type Array<unknown>
     * @default []
     */
    @Input()
    public get values(): Array<unknown> {
        return this._values;
    }

    public set values(value: Array<unknown>) {
        this._values = value;
    }

    /**
     * Called when a child `CheckBoxComponent` checked is changed.
     * Provides reference to {@link CheckBoxGroupChangedEventDetail} as event argument.
     *
     * @public
     * @eventProperty
     * @readonly
     * @type EventEmitter<CheckBoxGroupChangedEventDetail>
     * @default EventEmitter
     */
    @Output()
    public get changed(): EventEmitter<any> {
        return this._changed;
    }

    /**
     * Returns the `allChecked` property.
     *
     * @public
     * @readonly
     */
    public get allChecked(): boolean | null {
        return this._allChecked;
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public ngAfterContentInit(): void {
        this._isInitialized = true;

        this._itemsChangedSubscription = this._items?.changes.subscribe(() => {
            this.initalize();
        });

        // fix for inital selection
        setTimeout(() => {
            this.initalize();
        }, 1000);
    }

    /**
     * @public
     */
    public ngOnDestroy(): void {
        this._itemsChangedSubscription.unsubscribe();
        this._itemsChangedSubscriptions.forEach(x => x.unsubscribe());
    }

    /**
     * @public
     */
    public checkAll(): void {
        this._lockitemSubscriptions = true;
        this._items?.forEach(x => x.checked = true);
        this._allChecked = true;
        this._lockitemSubscriptions = false;

        this.onCheckBoxCheckChanged();
    }

    /**
     * @public
     */
    public uncheckAll(): void {
        this._lockitemSubscriptions = true;
        this._items?.forEach(x => x.checked = false);
        this._allChecked = false;
        this._lockitemSubscriptions = false;

        this.onCheckBoxCheckChanged();
    }

    /**
     * @private
     */
    private initalize(): void {
        this._itemsChangedSubscriptions.forEach(x => x.unsubscribe());

        if (this._items?.length) {
            this._items.forEach((checkBox) => {
                checkBox.name = this._name;
                checkBox.required = this.required;
                checkBox.checked = this._values.includes(checkBox.value);

                if (!checkBox.disabled) {
                    checkBox.disabled = this.disabled;
                }

                this._itemsChangedSubscriptions.push(
                    checkBox.change.subscribe(x => this.onCheckBoxCheckChanged()),
                );
            });
        }
    }

    /**
     * @private
     */
    private onCheckBoxCheckChanged(): void {
        if (!this._lockitemSubscriptions) {
            const checkedItems = this._items?.filter(x => x.checked ?? false) ?? [];
            const checkedItemsCount = checkedItems.length;

            if (checkedItemsCount === this._items?.length) {
                this._allChecked = true;
            } else if (checkedItemsCount === 0) {
                this._allChecked = false;
            } else {
                this._allChecked = null;
            }

            if (this._isInitialized) {
                this._changed.emit({ sender: this, values: checkedItems.map(x => x.value) });
            }
        }
    }

    // #endregion

}
